import parseGS1Values from './parseGS1Values';
import sequenceGS1String from './sequenceGS1String';

export default function containerValuesFromGS1(str) {
  const sequenced = {};
  try {
    const results = sequenceGS1String(str);
    console.log('Sequenced GS1 value results', results);
    if (results['01']) {
      const GTIN = results['01'].content;
      const { NDC } = (GTIN.match(/^003(?<NDC>\d{10})\d/)?.groups || {});
      sequenced.NDC = NDC;
    }
    if (results['10']) {
      sequenced.lotNumber = results['10'].content;
    }
    if (results['17']) {
      const [, year, month, day] = results['17'].content.match(/(\d{2})(\d{2})(\d{2})/);
      sequenced.expirationDate = `${month}/${day == '00' ? '01' : day}/${year}`;
    }
  }
  catch (err) {
    console.log('Errored while sequencing GS1 values: ', err.message);
  }
  // const { NDC, expirationDate, lotNumber} = parseGS1Values(str);
  const parsed = parseGS1Values(str);

  console.log('Sequenced GS1 values', sequenced);
  console.log('Parsed GS1 values', parsed);

  // Prefer sequenced values over parsed values
  return {
    ...parsed,
    ...sequenced,
  };
}