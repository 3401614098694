import { types } from 'mobx-state-tree';
import keyBy from 'lodash/keyBy';
import currency from 'currency.js';


import stores from 'bootstrap/store';


const equalityCheck = {
  ['copay']: (a, b) => currency(a).value === currency(b).value,
};

const cleanUp = {
  ['copay']: (value) => currency(value).format({ symbol: '' }),
};

export default types.model({
  prescriptionFillsFulfillmentId: types.maybeNull(types.string),
  prescriptionFillsFulfillment: types.maybeNull(types.frozen()),
  isShowingReceipt: types.optional(types.boolean, false),
  patientSignatureUrl: types.maybeNull(types.string),
  isEditingPrescriptions: types.optional(types.boolean, false),
  fillEditsById: types.optional(types.map(types.frozen()), {}),
})
.props({
  http: types.optional(types.model({
    loadFulfillmentAsReceipt: types.optional(types.number, 0),
    updateFills: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  setFillEdit: (fillId, key, value) => {
    if (!self.fillEditsById.has(fillId)) self.fillEditsById.set(fillId, {});

    self.fillEditsById.set(fillId, {
      ...self.fillEditsById.get(fillId),
      [key]: value,
    });
  },
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setInitialState: () => {
    self.prescriptionFillsFulfillmentId = null;
    self.prescriptionFillsFulfillment = null;
    self.http = {};
    self.isShowingReceipt = false;
    self.patientSignatureUrl = null;
    self.isEditingPrescriptions = false;
    self.resetFillEdits();
  },
  resetFillEdits: () => {
    self.fillEditsById.clear();
  },
  toggleIsEditingPrescriptions: () => {
    self.resetFillEdits();
    self.setValue('isEditingPrescriptions', !self.isEditingPrescriptions);
  },
  cleanUpFillEdits: () => {
    self.fillEditsById.forEach((edits, fillId) => {
      self.fillEditsById.set(fillId, Object.entries(edits).reduce((acc, [key, value]) => {
        if (!cleanUp[key]) return acc;
        acc[key] = cleanUp[key](value);
        return acc;
      }, {}));
    });
  },
}))
.actions(self => ({
  loadFulfillmentAsReceipt: async () => {
    const prescriptionFillsFulfillmentId = self.prescriptionFillsFulfillmentId;

    if (!prescriptionFillsFulfillmentId) return;

    self.incrementHttp('loadFulfillmentAsReceipt');
    try {
      const result = await stores.global.prescriptionFillsFulfillment.getAsReceipt({ params: { prescriptionFillsFulfillmentId } });
      self.setValue('prescriptionFillsFulfillment', result);
    }
    catch (err) {
      console.error(err);
    }
    self.decrementHttp('loadFulfillmentAsReceipt');
  },
  saveEdits: async ({ actingPharmacistId }) => {
    self.setValue('isEditingPrescriptions', false);
    self.cleanUpFillEdits();

    self.incrementHttp('updateFills');

    try {
      await Promise.all(Array.from(self.fillEditsById.entries()).map(([fillId, edits]) => {
        return stores.global.prescriptionFills.upsertWithReferences(fillId, edits, { actingPharmacistId });
      }));

      await self.loadFulfillmentAsReceipt();
    }
    catch (err) {
      console.error(err);
    }

    self.decrementHttp('updateFills');
  },
}))
.views(self => ({
  get isBusy() {
    return Object.values(self.http).some(Boolean);
  },
  get fillsById() {
    return keyBy(self.prescriptionFillsFulfillment?.fulfillment?.prescriptionFills || [], 'id');
  },
  fillEditById(fillId, key) {
    const edit = self.fillEditsById.get(fillId)?.[key];
    if (edit === undefined) return self.fillsById[fillId]?.[key];
    return edit;
  },
  get anyFillEdits() {
    return Array.from(self.fillEditsById.entries()).some(([fillId, edits]) => {
      return Object.keys(edits).some(key => {
        const customEqualityCheck = equalityCheck[key];
        if (customEqualityCheck) return !customEqualityCheck(self.fillsById[fillId]?.[key], edits[key]);
        return self.fillEditById(fillId, key) !== self.fillsById[fillId]?.[key];
      });
    });
  },
}));
