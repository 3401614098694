import { types } from 'mobx-state-tree';
import { observable } from 'mobx';

const ActivePharmacistPromptStore = types
.model({
  // Identifies a batch of prompt requests. If a new prompt request is made while there is not yet
  // an identifier, it will use the same promptId. Cleared after identification.
  promptId: types.maybeNull(types.string),
  activePharmacistUser: types.maybeNull(types.frozen()),
  identifier: types.maybeNull(types.string),
  error: types.maybeNull(types.string),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  clearIdentity: (promptId = true) => {
    self.activePharmacistUser = null;
    self.identifier = null;
  },
  unlisten: (listener) => {
    self.listeners.delete(listener);
    if (!self.listeners.size) self.clearIdentity();
  },
  unlistenAll: () => {
    self.listeners.clear();
    self.clearIdentity();
  },
  listen: (listener) => {
    // If this is the first in a new batch of prompt requests, generate a new promptId.
    if (!self.listeners.size) self.promptId = crypto.randomUUID();

    // A prompt request means specifically a new request for identification, so any lingering
    // prior identification should be cleared.
    self.clearIdentity();

    self.listeners.add(listener);
    return { promptId: self.promptId, unlisten: () => self.unlisten(listener) };
  },
  
  reset: () => {
    self.unlistenAll();
    self.clearIdentity();
  },
}))
.volatile(self => ({
  // Must be deep: false, otherwise equality check for delete fail and listener will not be removed.
  listeners: observable.set([], { deep: false }),
}))
.views(self => ({
  get isVisible() {
    return !!self.listeners.size || !!self.error;
  },
}));

export const activePharmacistPromptStore = ActivePharmacistPromptStore.create({});
