import { types } from 'mobx-state-tree';

import stores from 'bootstrap/store';

import { CONTAINER_SIZE_BY_SLOT_SIZE, FILTERED_SLOT_SIZES } from '../constants';

export const KioskStorageRouteStore = types.model({})
.views(() => ({
  get forSlotsTable() {
    const { computedInventory } = stores.global.kiosks;
    if (!computedInventory) {
      return [];
    }

    const inventory = computedInventory.kiosk.computed_inventory.bySlotType;
    return FILTERED_SLOT_SIZES.map((slotType) => {
      const { count } = inventory[slotType] || { count: { purgeable: 0, total: 0, available: 0 } };
      return {
        containerSize: CONTAINER_SIZE_BY_SLOT_SIZE[slotType],
        purgeableSlotsCount: count.purgeable,
        totalSlotsCount: count.total,
        vacantSlotsCount: count.available,
      };
    });
  },
}));

export const kioskStorageRouteStore = KioskStorageRouteStore.create({});
