import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { getProps } from './helpers';

// Styles
import './status-display-style.scss';


const DispenseAttemptStatusDisplay = ({
  dispenseAttemptsAvailable,
  activeDispenseAttempt,
  source,
}) => {
  if (!dispenseAttemptsAvailable) return null;

  console.log('ACTIVE DISPENSE ATTEMPT', activeDispenseAttempt);

  const {
    title,
    className,
    icon,
  } = getProps(activeDispenseAttempt);

  const reviewer = [activeDispenseAttempt?.review?.pharmacist?.firstName, activeDispenseAttempt?.review?.pharmacist?.lastName].filter(Boolean).join(' ');

  return <div className='c-StatusDisplay'>
    <div className='row'>
      <div className='status-container'>
        <div className={className}>
          {
            title
          }
        </div>
      </div>
      {
        !!icon &&
        <div className='icon-container'>
          <img
            alt='Status'
            className='icon'
            src={icon}
          />
        </div>
      }
    </div>
    {/* {
      source &&
      <div className='row'>
        <div className='source'>
          {
            `${source.role}: ${source.name}`
          }
        </div>
      </div>
    } */}
    {
      reviewer.length &&
      <div className='row'>
        <div className='reviewer'>
          {
            reviewer
          }
        </div>
      </div>
    }
  </div>;
};


export default observer(DispenseAttemptStatusDisplay);
