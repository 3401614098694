import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

// Store
import { activePharmacistPromptStore } from './domain';
import stores from 'bootstrap/store';

// Views
import ActivePharmacistPromptView from './activePharmacistPromptView';
import useKeySequenceListener from 'hooks/useKeySequenceListener';
import useTimeout from 'hooks/useTimeout';

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const refs = new Set();

const DEFAULT_TIMEOUT = 1000 * 10; // 10 seconds

function ActivePharmacistPromptContainer() {
  const ref = useRef({});
  refs.add(ref.current);
  if (refs.size > 1) throw new Error('ActivePharmacistPrompt is meant to be a singleton, should only be mounted once');

  // Identify the prompt id that this instance was last responsible for
  const currentPromptId = useRef(null);

  // Local to component, we have the pharmacist tracked outside of the store.
  // This is to use for a slightly delayed way to display the pharmacist upon identification,
  // prior to setting on globally accessible store.
  const [pharmacist, setPharmacist] = React.useState(null);

  function clear(hard = false) {
    hard ? activePharmacistPromptStore.reset() : activePharmacistPromptStore.clearIdentity();
    setPharmacist(null);
  }

  useEffect(() => {
    return () => {
      refs.delete(ref.current);
      activePharmacistPromptStore.reset();
    };
  }, []);

  // Clear any identif
  const initiateTimedClearActive = useTimeout(() => {
    // If there is a new wave of identification requests, we don't do anything.
    // Any new prompt will have already cleared the active pharmacist, and we don't want
    // to inadvertently clear any new prompts' pharmacist.
    const newPromptId = activePharmacistPromptStore.promptId;
    if (newPromptId && (newPromptId !== currentPromptId.current)) return;

    console.log('Clearing active pharmacist from prompt due to timeout.');
    clear();
  }, DEFAULT_TIMEOUT);

  useKeySequenceListener({
    listening: activePharmacistPromptStore.isVisible,
    sequence: {
      pattern: '\\\\(\\d+)\\\\',
      maxLength: 100,
    },
    allowInputFocus: true, // We allow this because the Approve and Reject button are inputs for some reason
    onMatch: async (match) => {
      // Snapshot the current prompt id;
      currentPromptId.current = activePharmacistPromptStore.promptId;

      clear();

      const pharmacistExternalId = match[1];
      activePharmacistPromptStore.setValue('identifier', pharmacistExternalId);

      activePharmacistPromptStore.listeners.forEach(({ onIdentify }) => {
        onIdentify(pharmacistExternalId);
      });

      try {
        console.log('Getting by external id', pharmacistExternalId);
        const pharmacist = await stores.global.pharmacists.getByExternalIdentifier({ externalIdentifier: pharmacistExternalId });
        initiateTimedClearActive();
        // Set pharmacist locally for a slight delay before setting on the store
        setPharmacist(pharmacist);

        await delay(1500);
        activePharmacistPromptStore.setValue('activePharmacistUser', pharmacist);
        activePharmacistPromptStore.listeners.forEach(({ onPharmacist }) => {
          onPharmacist(pharmacist);
        });
        activePharmacistPromptStore.unlistenAll();
        setPharmacist(null); // Clear temporary local pharmacist now that it's set on the store
      }
      catch (error) {
        clear(true);
        activePharmacistPromptStore.setValue('error', 'Pharmacist Identification Unsuccessful');
        await delay(2000);
        activePharmacistPromptStore.setValue('error', null);
      }
    },
  });

  

  return (
    <ActivePharmacistPromptView
      activePharmacistPromptStore={activePharmacistPromptStore}
      pharmacist={pharmacist}
    />
  );
}

export default observer(ActivePharmacistPromptContainer);
