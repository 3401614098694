// Their image is 381x204 pixels

import times from 'lodash/times';

export default function getLabelXml({
  drugName,
  NDC,
  lotNumber,
  drugQuantity,
  expirationDate,
  imageData,
}) {
  lotNumber = lotNumber ?? '';
  drugQuantity = drugQuantity ?? '';
  expirationDate = expirationDate ?? '';

  const QRCode = ({ x, y, scale } = {}) => `<QRCodeObject>
    <Name>QRCodeObject0</Name>
    <Brushes>
      <BackgroundBrush>
        <SolidColorBrush>
          <Color A="1" R="1" G="1" B="1"></Color>
        </SolidColorBrush>
      </BackgroundBrush>
      <BorderBrush>
        <SolidColorBrush>
          <Color A="1" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </BorderBrush>
      <StrokeBrush>
        <SolidColorBrush>
          <Color A="1" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </StrokeBrush>
      <FillBrush>
        <SolidColorBrush>
          <Color A="1" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </FillBrush>
    </Brushes>
    <Rotation>Rotation0</Rotation>
    <OutlineThickness>1</OutlineThickness>
    <IsOutlined>False</IsOutlined>
    <BorderStyle>SolidLine</BorderStyle>
    <Margin>
      <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
    </Margin>
    <BarcodeFormat>QRCode</BarcodeFormat>
    <Data>
      <DataString>NDC ${NDC} quantity ${drugQuantity} expiresAt ${expirationDate} lotNumber ${lotNumber} </DataString>
    </Data>
    <HorizontalAlignment>Center</HorizontalAlignment>
    <VerticalAlignment>Middle</VerticalAlignment>
    <Size>AutoFit</Size>
    <EQRCodeType>QRCodeText</EQRCodeType>
    <TextDataHolder>
      <Value>NDC ${NDC} quantity ${drugQuantity} expiresAt ${expirationDate} lotNumber ${lotNumber} </Value>
    </TextDataHolder>
    <ObjectLayout>
      <DYMOPoint>
        <X>${x}</X>
        <Y>${y}</Y>
      </DYMOPoint>
      <Size>
        <Width>${scale}</Width>
        <Height>${scale}</Height>
      </Size>
    </ObjectLayout>
  </QRCodeObject>`;

  const TextObject = ({ x, y, content, name, width = 2.4 } = {}) => `
  <TextObject>
    <Name>${name}</Name>
    <Brushes>
      <BackgroundBrush>
        <SolidColorBrush>
          <Color A="0" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </BackgroundBrush>
      <BorderBrush>
        <SolidColorBrush>
          <Color A="1" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </BorderBrush>
      <StrokeBrush>
        <SolidColorBrush>
          <Color A="1" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </StrokeBrush>
      <FillBrush>
        <SolidColorBrush>
          <Color A="0" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </FillBrush>
    </Brushes>
    <Rotation>Rotation0</Rotation>
    <OutlineThickness>1</OutlineThickness>
    <IsOutlined>False</IsOutlined>
    <BorderStyle>SolidLine</BorderStyle>
    <Margin>
      <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
    </Margin>
    <HorizontalAlignment>Left</HorizontalAlignment>
    <VerticalAlignment>Middle</VerticalAlignment>
    <FitMode>AlwaysFit</FitMode>
    <IsVertical>False</IsVertical>
    <FormattedText>
      <FitMode>AlwaysFit</FitMode>
      <HorizontalAlignment>Left</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <IsVertical>False</IsVertical>
      <LineTextSpan>
        <TextSpan>
          <Text>${content}</Text>
          <FontInfo>
            <FontName>Helvetica</FontName>
            <FontSize>14</FontSize>
            <IsBold>False</IsBold>
            <IsItalic>False</IsItalic>
            <IsUnderline>False</IsUnderline>
            <FontBrush>
              <SolidColorBrush>
                <Color A="1" R="0" G="0" B="0"></Color>
              </SolidColorBrush>
            </FontBrush>
          </FontInfo>
        </TextSpan>
      </LineTextSpan>
    </FormattedText>
    <ObjectLayout>
      <DYMOPoint>
        <X>${x}</X>
        <Y>${y}</Y>
      </DYMOPoint>
      <Size>
        <Width>${width}</Width>
        <Height>0.2</Height>
      </Size>
    </ObjectLayout>
  </TextObject>`.trim();

  const QRArray = times(5, (i) => QRCode({ x: (i * 0.75) + 0.23, y: 0.9, scale: 0.62 }));

  return `<?xml version="1.0" encoding="utf-8"?>
  <DesktopLabel Version="1">
    <DYMOLabel Version="3">
      <Description>DYMO Label</Description>
      <Orientation>Landscape</Orientation>
      <LabelName>Shipping30573</LabelName>
      <InitialLength>0</InitialLength>
      <BorderStyle>SolidLine</BorderStyle>
      <DYMORect>
        <DYMOPoint>
          <X>0.2133333</X>
          <Y>0.06</Y>
        </DYMOPoint>
        <Size>
          <Width>3.696667</Width>
          <Height>2.026667</Height>
        </Size>
      </DYMORect>
      <BorderColor>
        <SolidColorBrush>
          <Color A="1" R="0" G="0" B="0"></Color>
        </SolidColorBrush>
      </BorderColor>
      <BorderThickness>1</BorderThickness>
      <Show_Border>False</Show_Border>
      <DynamicLayoutManager>
        <RotationBehavior>ClearObjects</RotationBehavior>
        <LabelObjects>
          ${TextObject({ x: 0.2, y: 0.45, content: `NDC: ${NDC}`, name: 'NDC' })}
          ${TextObject({ x: 2.3, y: 0.45, content: `Qty: ${drugQuantity}`, name: 'Quantity' })}
          ${TextObject({ x: 0.2, y: 0.67, content: `Exp: ${expirationDate}`, name: 'Expiration' })}
          ${TextObject({ x: 1.9, y: 0.67, content: `Lot: ${lotNumber}`, name: 'Lot', width: 1.8 })}
          <ImageObject>
            <Name>ImageObject0</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <Data>${imageData}</Data>
            <ScaleMode>Uniform</ScaleMode>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <ObjectLayout>
              <DYMOPoint>
                <X>0.2133333</X>
                <Y>1.57</Y>
              </DYMOPoint>
              <Size>
                <Width>3.696667</Width>
                <Height>0.5166666</Height>
              </Size>
            </ObjectLayout>
          </ImageObject>
          ${QRArray.join('\n')}
          <TextObject>
            <Name>TextObject1</Name>
            <Brushes>
              <BackgroundBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BackgroundBrush>
              <BorderBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </BorderBrush>
              <StrokeBrush>
                <SolidColorBrush>
                  <Color A="1" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </StrokeBrush>
              <FillBrush>
                <SolidColorBrush>
                  <Color A="0" R="0" G="0" B="0"></Color>
                </SolidColorBrush>
              </FillBrush>
            </Brushes>
            <Rotation>Rotation0</Rotation>
            <OutlineThickness>1</OutlineThickness>
            <IsOutlined>False</IsOutlined>
            <BorderStyle>SolidLine</BorderStyle>
            <Margin>
              <DYMOThickness Left="0" Top="0" Right="0" Bottom="0" />
            </Margin>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <FitMode>AlwaysFit</FitMode>
            <IsVertical>False</IsVertical>
            <FormattedText>
              <FitMode>AlwaysFit</FitMode>
              <HorizontalAlignment>Left</HorizontalAlignment>
              <VerticalAlignment>Middle</VerticalAlignment>
              <IsVertical>False</IsVertical>
              <LineTextSpan>
                <TextSpan>
                  <Text>${drugName}</Text>
                  <FontInfo>
                    <FontName>Helvetica</FontName>
                    <FontSize>9</FontSize>
                    <IsBold>False</IsBold>
                    <IsItalic>False</IsItalic>
                    <IsUnderline>False</IsUnderline>
                    <FontBrush>
                      <SolidColorBrush>
                        <Color A="1" R="0" G="0" B="0"></Color>
                      </SolidColorBrush>
                    </FontBrush>
                  </FontInfo>
                </TextSpan>
              </LineTextSpan>
            </FormattedText>
            <ObjectLayout>
              <DYMOPoint>
                <X>0.2133333</X>
                <Y>0.1593271</Y>
              </DYMOPoint>
              <Size>
                <Width>3.696667</Width>
                <Height>0.2858765</Height>
              </Size>
            </ObjectLayout>
          </TextObject>
        </LabelObjects>
      </DynamicLayoutManager>
    </DYMOLabel>
    <LabelApplication>Blank</LabelApplication>
    <DataTable>
      <Columns></Columns>
      <Rows></Rows>
    </DataTable>
  </DesktopLabel>`.trim();
}