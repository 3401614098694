import stores from 'bootstrap/store';

// Icons
import CallbackRequestIcon from 'assets/icons/callback.svg';
import CallbackRequestActiveIcon from 'assets/icons/callback-active.svg';
import ContainerIcon from 'assets/icons/container.svg';
import ContainerActiveIcon from 'assets/icons/container-active.svg';
import KioskIcon from 'assets/icons/kiosk.svg';
import KioskActiveIcon from 'assets/icons/kiosk-active.svg';
import VideoIcon from 'assets/icons/video.svg';
import VideoActiveIcon from 'assets/icons/video-active.svg';
import PrescriptionIcon from 'assets/icons/prescriptions.svg';
import PrescriptionActiveIcon from 'assets/icons/prescriptions-active.svg';
import MailOrderIcon from 'assets/icons/mail-order.svg';
import MailOrderActiveIcon from 'assets/icons/mail-order-active.svg';
import PatientIcon from 'assets/icons/patient.svg';
import PatientActiveIcon from 'assets/icons/patient-active.svg';
import TransferIcon from 'assets/icons/transfer.svg';
import TransferActiveIcon from 'assets/icons/transfer-active.svg';

// Sounds
import videoConferenceInvitationSound from 'assets/sounds/video-conference-invitation.wav';

// Components
import DispenseAttemptNotifications from '../components/notifications/components/dispense-attempts';
import MailOrderedPrescriptionNotifications from '../components/notifications/components/mail-ordered-prescriptions';
import NewPatientNotifications from '../components/notifications/components/new-patients';
import CallRequestNotifications from '../components/notifications/components/call-requests';
import ErroredKioskNotifications from '../components/notifications/components/errored-kiosks';
import TransferPrescriptionNotifications from '../components/notifications/components/transfer-prescriptions';
import UnroutedPrescriptionNotifications from '../components/notifications/components/unrouted-prescriptions';
import VideoConferenceNotifications from '../components/notifications/components/video-conferences';

// Singletons
import { activePharmacistPromptStore } from 'singletons/activePharmacistPrompt/domain';

export const NOTIFICATION_TYPE = {
  DISPENSE_ATTEMPTS: 'dispenseAttempts',
  VIDEO_CONFERENCES: 'videoConferences',
  CALL_REQUESTS: 'callRequests',
  ERRORED_KIOSKS: 'erroredKiosks',
  MAIL_ORDERED_PRESCRIPTIONS: 'mailOrderedPrescriptions',
  // TRANSFER_PRESCRIPTIONS: 'transferPrescriptions',
  // UN_ROUTED_PRESCRIPTIONS: 'unRoutedPrescriptions',
  // NEW_PATIENTS: 'newPatients',
};
export const NOTIFICATION_TYPES = Object.values(NOTIFICATION_TYPE);

export const NOTIFICATIONS_DATA = {
  [NOTIFICATION_TYPE.DISPENSE_ATTEMPTS]: {
    component: DispenseAttemptNotifications,
    loadData: () => {
      const { isActiveUserPharmacist } = stores.global.users;
      if (isActiveUserPharmacist) return stores.global.dispenseAttempts.listPendingForNotificationsPane();
    },
    preview: {
      icon: ContainerIcon,
      activeIcon: ContainerActiveIcon,
      getNotificationsCount: () => stores.global.dispenseAttempts.pendingArray.length,
    },
    sound: null,
    refetchInterval: null,
  },
  [NOTIFICATION_TYPE.NEW_PATIENTS]: {
    component: NewPatientNotifications,
    loadData: () => stores.global.patients.listPendingForNotificationsPane(),
    preview: {
      icon: PatientIcon,
      activeIcon: PatientActiveIcon,
      getNotificationsCount: () => stores.global.patients.dataPendingList.length,
    },
    sound: null,
    refetchInterval: null,
  },
  [NOTIFICATION_TYPE.VIDEO_CONFERENCES]: {
    component: VideoConferenceNotifications,
    loadData: () => {
      const { isActiveUserPharmacist } = stores.global.users;
      if (isActiveUserPharmacist) return stores.global.videoConferences.listActive();
    },
    preview: {
      icon: VideoIcon,
      activeIcon: VideoActiveIcon,
      getNotificationsCount: () => stores.global.videoConferences.pendingArray.length,
    },
    sound: {
      audio: new Audio(videoConferenceInvitationSound),
      interval: 5000,
      active: () => {
        return Boolean(
          stores.global.videoConferences.pendingArray.length &&
          !activePharmacistPromptStore.isVisible &&
          !stores.global.videoConferences.showingVideoConferenceModal
        );
      },
    },
    refetchInterval: null,
  },
  [NOTIFICATION_TYPE.CALL_REQUESTS]: {
    component: CallRequestNotifications,
    loadData: () => stores.global.callRequests.listForNotificationsPane(),
    preview: {
      icon: CallbackRequestIcon,
      activeIcon: CallbackRequestActiveIcon,
      getNotificationsCount: () => stores.global.callRequests.queuedList.length,
    },
    sound: null,
    refetchInterval: null,
  },
  [NOTIFICATION_TYPE.ERRORED_KIOSKS]: {
    component: ErroredKioskNotifications,
    loadData: () => stores.global.kiosks.listErroredForPharmacist(),
    preview: {
      icon: KioskIcon,
      activeIcon: KioskActiveIcon,
      getNotificationsCount: () => stores.global.kiosks.erroredList.length,
      displayNotificationsCount: false,
    },
    sound: null,
    refetchInterval: 300000, // 5 minutes
  },
  [NOTIFICATION_TYPE.UN_ROUTED_PRESCRIPTIONS]: {
    component: UnroutedPrescriptionNotifications,
    loadData: () => stores.global.prescriptionFills.listPendingForNotificationsPane(),
    preview: {
      icon: PrescriptionIcon,
      activeIcon: PrescriptionActiveIcon,
      getNotificationsCount: () => stores.global.prescriptionFills.pendingArray.length,
    },
    sound: null,
    refetchInterval: null,
  },
  // TODO uncommenting this should just work
  // [NOTIFICATION_TYPE.MAIL_ORDERED_PRESCRIPTIONS]: {
  //   component: MailOrderedPrescriptionNotifications,
  //   loadData: () => stores.global.prescriptionFills.listMailOrderedForNotificationsPane(),
  //   preview: {
  //     icon: MailOrderIcon,
  //     activeIcon: MailOrderActiveIcon,
  //     getNotificationsCount: () => stores.global.prescriptionFills.mailOrderedForNotificationsPaneArray.length,
  //   },
  //   sound: null,
  //   refetchInterval: null,
  // },
  [NOTIFICATION_TYPE.TRANSFER_PRESCRIPTIONS]: {
    component: TransferPrescriptionNotifications,
    loadData: () => stores.global.prescriptionFills.listTransfersForNotificationsPane(),
    preview: {
      icon: TransferIcon,
      activeIcon: TransferActiveIcon,
      getNotificationsCount: () => stores.global.prescriptionFills.transfersForNotificationsPaneArray.length,
    },
    sound: null,
    refetchInterval: null,
  },
};
