import React from 'react';
import { observer } from 'mobx-react';

import CaptionValueInput from 'components/caption-value-input';
import ContentScreen from 'components/content-screen';
import LabelledCheckbox from 'components/labelled-checkbox';

import { EMAIL_RE, PHONE_NUMBER_RE } from './constants';

import './send-receipt-view.scss';
import Button from 'components/button';


const SendReceiptView = ({
  patientEmail,
  sendReceiptModalRouteStore,
  sendReceipt,
  onClose,
}) => {
  const emailIsPatientEmail = sendReceiptModalRouteStore.email === patientEmail;
 
  return (
    <div
      className='c-SendReceiptView'
    >
      <ContentScreen active={sendReceiptModalRouteStore.isBusy} />
      <h2 className='title'>Send Receipt</h2>
      <div className='form-area-container'>
        <div className='form-area'>
          <CaptionValueInput
            inputFieldClassNames={['max-width']}
            caption='Email'
            value={sendReceiptModalRouteStore.email}
            validationRegex={EMAIL_RE}
            onChange={(value) => sendReceiptModalRouteStore.setValue('email', value)}
            style={{ flex: '0 0 auto'}}
          />
          {
            emailIsPatientEmail
              ? <div className='email-match-text'>Email is set to patient&apos;s email</div>
              : <div className='receipt-checkbox'>
                <LabelledCheckbox
                  isChecked={sendReceiptModalRouteStore.shouldSaveEmail}
                  label="Save as Patient's Email"
                  onChange={(value) => sendReceiptModalRouteStore.setValue('shouldSaveEmail', value)}
                />
              </div>
          }
        </div>
      </div>

      <div className='actions-area'>
        <Button
          color='red'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color='blue'
          disabled={!sendReceiptModalRouteStore.canBeSubmitted}
          onClick={sendReceipt}
        >
          Send Receipt
        </Button>
      </div>
    </div>
  );
};

export default observer(SendReceiptView);
