import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import Status from './components/status';
import Temperature from './components/temperature';

import { isKioskOnline } from 'models/kiosks/helpers';

const KiosksListHeader = ({
  kiosk,
}) => {
  const latestStatusDate = _get(kiosk, 'latestStatus.createdAt');
  const isOnline = isKioskOnline(latestStatusDate);

  return <React.Fragment>
    <div className='title'>
      {
        kiosk.name
      }
    </div>
    <div className='kiosk-list-item-right-side'>
      <Status
        isOnline={isOnline}
      />
      <Temperature
        temperature={kiosk?.computed_status?.temperature}
      />
    </div>
  </React.Fragment>;
};

export default observer(KiosksListHeader);
