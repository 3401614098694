import { types } from 'mobx-state-tree';
import { set as _set, startCase as _startCase } from 'lodash';
import isNil from 'lodash/isNil';
import moment from 'moment';

import { FULFILLMENT_TYPE } from 'models/prescription-fills/constants';

export const PrescriptionFillsRouteStore = types.model({
})
.props({
  prescriptionFillsById: types.optional(types.map(types.frozen()), {}),
  activePrescriptionFulfillmentId: types.maybeNull(types.string),
  filterValue: types.optional(types.string, ''),
  activePrescriptionFillId: types.maybeNull(types.string),
  http: types.optional(types.model({
    listing: types.optional(types.number, 0),
    cancelling: types.optional(types.number, 0),
  }), {}),
  dateRange: types.optional(types.model({
    start: types.optional(types.Date, new Date()),
    end: types.optional(types.Date, new Date()),
  }), {}),
  showingPrescriptionFillEditModal: types.optional(types.boolean, false),
  showingSendReceiptModal: types.optional(types.boolean, false),
})
.actions(self => ({
  setValue: (path, value) => _set(self, path, value),
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  mergePrescriptionFillsById: (prescriptionFillsById) => self.prescriptionFillsById.merge(prescriptionFillsById),
  setPrescriptionFillsById: (prescriptionFillsById) => {
    self.prescriptionFillsById = {};
    self.prescriptionFillsById = prescriptionFillsById;
  },
  setStartDate: (date) => {
    if (date === null) return;
    // Set to start of day, local time, using moment.
    date = moment(date).startOf('day').toDate();
    self.dateRange.start = date;
  },
  setEndDate: (date) => {
    if (date === null) return;
    // Set to end of day, local time, using moment.
    date = moment(date).endOf('day').toDate();
    self.dateRange.end = date;
  },
  setInitialState: () => {
    self.prescriptionFillsById = {};
    self.filterValue = '';
    self.activePrescriptionFillId = null;
    self.http = {};
    self.showingPrescriptionFillEditModal = false;
    self.setStartDate(moment().startOf('month').toDate());
    self.setEndDate(moment().endOf('day').toDate());
    self.showingSendReceiptModal = false;
  }
}))
.views(self => ({
  get prescriptionFillsList() {
    return Array.from(self.prescriptionFillsById.values());
  },
  get isBusy() {
    return Boolean(self.http.cancelling || self.http.listing);
  },
  prescriptionFillHasDispenseAttempt: (prescriptionFill) => {
    return Boolean(prescriptionFill?.dispenseAttempt);
  },
}))
.views(self => ({
  get loading() {
    return Boolean(self.http.listing);
  },
  get activePrescriptionFill() {
    return self.activePrescriptionFillId && self.prescriptionFillsById.get(self.activePrescriptionFillId);
  },
  get forTable() {
    return self.prescriptionFillsList.map((prescriptionFill) => {
      const active = prescriptionFill?.computed_active;
      const filled = prescriptionFill?.computed_filled;
      const locked = prescriptionFill?.prescriptionFillsFulfillment?.computed_locked;

      const reasonForNotFilled =
        prescriptionFill.declined
          ? 'Declined at Kiosk'
          : prescriptionFill.computed_rejected
            ? 'Rejected by Pharmacist'
            : prescriptionFill.computed_cancelled // will be added
              ? 'Cancelled by Pharmacist'
              : null;

      const filledText = active
        ? ''
        : filled
          ? prescriptionFill?.fulfillmentType === FULFILLMENT_TYPE.MAIL_ORDER
            ? 'Yes (Mail Order)'
            : 'Yes'
          : reasonForNotFilled
            ? `No (${reasonForNotFilled})`
            : 'No';

      let paymentType = prescriptionFill?.prescriptionFillsFulfillment?.pffPayment?.payment?.type || null;
      if (paymentType) {
        paymentType = _startCase(paymentType.toLowerCase());
      }

      const hasSuccessfulPayment = (() => {
        console.log('PAYMENT', prescriptionFill?.prescriptionFillsFulfillment?.pffPayment?.payment);
        // Field is computed_succes rather than computed_successful due to postgres cutting off last characters.
        const successful = prescriptionFill?.prescriptionFillsFulfillment?.pffPayment?.payment?.computed_succes;
        return successful === true ? 'Successful' : successful === false ? 'Failed' : null;
      })();

      return {
        id: prescriptionFill.id,
        rxId: prescriptionFill.prescription?.rxId,
        drug: prescriptionFill.prescription?.drug,
        patient: prescriptionFill.prescription?.patient,
        quantity: prescriptionFill.quantity,
        reasonForNotFilled,
        hasCopay: !isNil(prescriptionFill.copay),
        copay: prescriptionFill.copay ? `$${prescriptionFill.copay}` : null,
        hasDispenseAttempt: self.prescriptionFillHasDispenseAttempt(prescriptionFill),
        hasFulfillmentTypeSpecified: Boolean(prescriptionFill.fulfillmentType),
        routedKioskName: prescriptionFill?.prescriptionFillKioskRouting?.kiosk?.name || null,
        filled,
        filledText,
        locked,
        active,
        paymentType,
        hasSuccessfulPayment,
        createdAt: new Date(prescriptionFill.createdAt).toLocaleString(),
        fulfillmentId: prescriptionFill.prescriptionFillsFulfillmentId,
      };
    });
  },
}));

export const prescriptionFillsRouteStore = PrescriptionFillsRouteStore.create({});
