import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';

// Views
import ReceiptView from'./receiptView';


function ReceiptContainer({
  receipt,
  onPrintReceipt,
  onRequestReload,
  onClose,
}) {
  const [isSendingReceipt, setIsSendingReceipt] = useState(false);
  useEffect(() => {
    if (!isSendingReceipt) onRequestReload();
  }, [isSendingReceipt]);

  return <ReceiptView
    receipt={receipt}
    onPrintReceipt={onPrintReceipt}
    isSendingReceipt={isSendingReceipt}
    setIsSendingReceipt={setIsSendingReceipt}
    onClose={onClose}
  />;
}

export default observer(ReceiptContainer);
