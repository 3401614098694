import { flow, types } from 'mobx-state-tree';
import { values } from 'mobx';
import { keyBy as _keyBy, get as _get } from 'lodash';

import { PHARMACIST } from './constants/types';

import areArrayContentsSame from 'utils/areArrayContentsSame';

import base from 'models/base';

import { MODEL_NAME, SCHEMA } from './constants/schema';

import stores from 'bootstrap/store';

const UserModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {
      // Get Current User.
      getCurrent: {
        method: 'GET',
        urlFragment: () => '/current',
        onSuccess: (self, {response = {}}) => {
          self.activeUser = response;
          self.activeUserRoles = response.roles;

          const token = stores.global.auth.activeToken || self.activeToken;
          self.setUserData({ token, user: response });
        }
      },
      // Login.
      login: {
        method: 'POST',
        onSuccess: (self, {response = {}}) => {
          if (_get(response, 'token.access')) {
            const activeToken = response.token.access;
            self.activeToken = activeToken;
            self.setUserData({
              token: activeToken,
              user: response.user,
            });
          }
        }
      },
      signUp: {
        method: 'POST',
        urlFragment: () => '/signup',
        onSuccess: (self, {response = {}}) => {
          self.activeToken = response.token.access;
        }
      },
      resetPassword: {
        method: 'POST',
        urlFragment: () => '/send-reset-password-email'
      },
      changePassword: {
        method: 'POST',
        urlFragment: () => '/change-password'
      }
    }
  }
});

UserModel.configureStore((store) => {
  return store
  .props({
    activeUser: types.maybeNull(types.frozen()),
    activeUserRoles: types.optional(types.array(types.string), []),
    activeToken: types.maybeNull(types.string),
    userDataById: types.optional(types.map(types.frozen()), {}),
    dataForPharmacist: types.optional(types.map(types.frozen()), {}),
    entriesCount: types.optional(types.number, 0)
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    setUserData: ({ token, user }) => {
      self.activeToken = token;
      self.userDataById.set(user.id, { user, token });
      localStorage.setItem('userDataById', JSON.stringify(self.userDataById));
    },
    deleteUserDataById: (userId) => {
      self.userDataById.delete(userId);
      localStorage.setItem('userDataById', JSON.stringify(self.userDataById));
    },
    logOut: () => {
      const activeUserId = self.activeUser.id;
      
      self.deleteUserDataById(activeUserId);
      if (self.userOptions.length) {
        const newUserId = self.userOptions[0].id;
        const { token } = self.userDataById.get(newUserId);
        self.activeToken = token;
      } else {
        self.activeToken = null;
      }
    },
    listUsers: flow(function* (config = {}) {
      const response = yield self.list(config);
      const data = response.data || response || [];

      // If data contains the same elements as dataForPharmacist but in reverse order,
      // mobx-state-tree's map treats it as the same set of elements, so views won't rerender.
      const responseIds = data.map(i => i.id);
      const dataForPharmacistIds = self.dataAsArray.map(i => i.id);
      if (areArrayContentsSame(responseIds, dataForPharmacistIds)) self.dataForPharmacist = {};
      self.dataForPharmacist = _keyBy(data, 'id');

      return response;
    }),
    listForPharmacist: flow(function* (config = {}) {
      config.urlFragment = () => '/for-pharmacist';
      const response = yield self.list(config);
      const data = response.data || response || [];

      self.dataForPharmacist.merge(_keyBy(data, 'id'));

      return response;
    }),
    countUsers: flow(function* (config = {}) {
      config.urlFragment = () => '/count';

      const response = yield self.list(config);
      self.entriesCount = response || 0;

      return response;
    }),
    createUser: (config = {}) => {
      config.urlFragment = () => '/signup';
      return self.post(config);
    },
    updateUser: (config = {}) => {
      return self.put(config);
    },
    deleteUser: (config = {}) => {
      return self.delete(config);
    },
    verifyLoginCode: (authMultifactorCodeToken, code) => {
      return self.post({
        urlFragment: () => '/login/verify-code',
        headers: {
          AuthMultifactorCodeToken: authMultifactorCodeToken,
        },
        body: {
          code,
        },
      });
    },
  }))
  .views(self => ({
    get dataAsArray() {
      return Array.from(self.dataForPharmacist.values());
    },
    isActiveUserRole(role) {
      return self.activeUserRoles && self.activeUserRoles.includes(role);
    },
    get isActiveUserAdmin() {
      return self.activeUser && self.activeUser.isAdmin;
    },
    get isActiveUserPharmacist() {
      return self.activeUserRoles && self.activeUserRoles.includes(PHARMACIST);
    },
    get userOptions() {
      return values(self.userDataById).map(({ user }) => user).sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));
    },
  }));
});


export default UserModel;
