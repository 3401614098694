import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import HeaderView from './header-view';

import stores from 'bootstrap/store';

const HeaderContainer = () => {
  const setPharmacistStatus = useCallback((status) => {
    return stores.global.pharmacists.setStatus({
      body: {
        status,
      },
    });
  }, []);
  
  const logOut = useCallback(() => {
    stores.global.users.logOut();
    if (stores.global.users.userOptions.length) {
      return window.location.reload();
    }
  }, []);

  const openCameraConfiguration = useCallback(() => {
    stores.global.cameras.setValue('showingCameraConfigurationModal', true);
  }, []);

  const redirectToLogin = useCallback(() => {
    return stores.router.history.push('/login');
  }, []);

  const onUserChange = useCallback(async (userId) => {
    const { token } = stores.global.users.userDataById.get(userId);
    stores.global.users.setValue('activeToken', token);
    return window.location.reload();
  }, []);

  return <HeaderView
    activeUser={stores.global.users.activeUser}
    userOptions={stores.global.users.userOptions}
    isActiveUserPharmacist={stores.global.users.isActiveUserPharmacist}
    isSettingStatus={!!stores.global.pharmacists.http.settingStatus}
    setPharmacistStatus={setPharmacistStatus}
    pharmacistStatus={stores.global.pharmacists.status}
    logOut={logOut}
    openCameraConfiguration={openCameraConfiguration}
    redirectToLogin={redirectToLogin}
    onUserChange={onUserChange}
  />;
};

export default observer(HeaderContainer);
