import { get as _get } from 'lodash';

import stores from 'bootstrap/store';

export default ({ actingPharmacistId }) => {
  const store = {
    global: {
      dispenseAttempts: stores.global.dispenseAttempts,
      videoConferences: stores.global.videoConferences,
    },
    route: {
      dispenseAttempts: stores.route.dispenseAttempts.domain,
    },
  };

  const dispenseAttemptId = _get(store.global.dispenseAttempts, 'rejectionModal.dispenseAttemptId') ||
    _get(store.route.dispenseAttempts, 'activeDispenseAttempt.id');
  if (!dispenseAttemptId) return;

  const {
    rejectContainer,
    rejectPrescription,
    reason,
  } = store.global.dispenseAttempts.rejectionModal;

  return store.global.dispenseAttempts.reject({
    params: {
      dispenseAttemptId,
    },
    body: {
      notes: reason.value.trim(),
      approveContainer: !rejectContainer,
      approvePrescription: !rejectPrescription,
      actingPharmacistId,
    },
  })
  .then(() => {
    store.global.dispenseAttempts.resetRejectionModal();
    if (store.global.videoConferences.showingVideoConferenceModal ) store.global.videoConferences.listActive();
    return store.global.dispenseAttempts.listPendingForNotificationsPane();
  });
};
