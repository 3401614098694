export default function getLabelSetXml() {
  return `<?xml version="1.0" encoding="UTF-8"?>
  <LabelSet xmlns:xs="http://www.w3.org/2001/XMLSchema">
      <LabelRecord>
          <ObjectData Name="ExampleObject">
              <StyledText>
                  <Element>
                      <String>Sample Text</String>
                      <Attributes>
                          <Font Family="Arial" Size="12.0" Bold="True" Italic="False" Underline="False" Strikeout="False"/>
                          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
                      </Attributes>
                  </Element>
              </StyledText>
          </ObjectData>
      </LabelRecord>
  </LabelSet>`.trim();
}
