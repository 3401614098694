import { apiWebSocket, apiWebSocketEmit } from '../../services/api-websocket';

import callRequestPharmacistActionsCreateHandler from './handlers/callRequestPharmacistActions:create';
import callRequestsCreateHandler from './handlers/callRequests:create';
import connectHandler from './handlers/connect';
import containersAddLabelledImageHandler from './handlers/containers:addLabelledImage';
import containersAddPrelabelledImageHandler from './handlers/containers:addPrelabelledImage';
import containersLabelledHandler from './handlers/containers:labelled';
import dispenseAttemptsInactiveHandler from './handlers/dispenseAttempts:inactive';
import dispenseAttemptReviewsCreateHandler from './handlers/dispenseAttemptReviews:create';
import patientsCreateHandler from './handlers/patients:create';
import patientsPharmacyDataCaptureHandler from './handlers/patients:pharmacyDataCapture';
import pharmacistsSetStatusHandler from './handlers/pharmacists:setStatus';
import prescriptionFillMailOrdersCreateHandler from './handlers/prescriptionFillMailOrders:create';
// import prescriptionFillTransfersCreateHandler from './handlers/prescriptionFillTransfers:create';
import kioskDrugThresholdsUpsertHandler from './handlers/kioskDrugThresholds:upsert';
import kioskStatusesCreateHandler from './handlers/kioskStatuses:create';
import videoConferenceInvitationsCreateHandler from './handlers/videoConferenceInvitations:create';
import videoConferenceInvitationsStatusChangeHandler from './handlers/videoConferenceInvitations:statusChange';
import videoConferenceInvitationsRejectHandler from './handlers/videoConferenceInvitations:reject';
import videoConferenceParticipantConnectionsDisconnectHandler from './handlers/videoConferenceParticipantConnections:disconnect';
import prescriptionFillPharmacistConsultationRequestsCreateHandler from './handlers/prescriptionFillPharmacistConsultationRequests:create';

const handlers = [
  callRequestPharmacistActionsCreateHandler,
  callRequestsCreateHandler,
  connectHandler,
  containersAddLabelledImageHandler,
  containersAddPrelabelledImageHandler,
  containersLabelledHandler,
  dispenseAttemptsInactiveHandler,
  dispenseAttemptReviewsCreateHandler,
  patientsCreateHandler,
  patientsPharmacyDataCaptureHandler,
  pharmacistsSetStatusHandler,
  prescriptionFillMailOrdersCreateHandler,
  // prescriptionFillTransfersCreateHandler,
  kioskStatusesCreateHandler,
  kioskDrugThresholdsUpsertHandler,
  videoConferenceInvitationsCreateHandler,
  videoConferenceInvitationsStatusChangeHandler,
  videoConferenceInvitationsRejectHandler,
  videoConferenceParticipantConnectionsDisconnectHandler,
  prescriptionFillPharmacistConsultationRequestsCreateHandler,
];

handlers.forEach((handler) => handler({ apiWebSocket, apiWebSocketEmit }));
