import { keyBy as _keyBy } from 'lodash';
import { flow, types } from 'mobx-state-tree';
import { values } from 'mobx';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';

const FormularyEntryModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

FormularyEntryModel.configureStore((store) => {
  return store
  .props({
    data: types.optional(types.map(types.frozen()), {}),
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    listFormularyEntries: flow(function* (config = {}) {
      const response = yield self.list(config);
      return response;
    }),
  }))
  .views(self => ({
    get dataList() {
      return values(self.data);
    },
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default FormularyEntryModel;
