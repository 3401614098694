import stores from 'bootstrap/store';
import { sendReceiptModalRouteStore } from '../domain/store';

const sendReceipt = async (prescriptionFillsFulfillmentId) => {
  if (!sendReceiptModalRouteStore.email) return;

  sendReceiptModalRouteStore.incrementHttp('sendReceipt');
  return stores.global.userContactEvents.sendReceipt({
    params: { prescriptionFillsFulfillmentId },
    body: {
      email: sendReceiptModalRouteStore.email,
      shouldEmail: true,
    },
  })
  .finally(() => sendReceiptModalRouteStore.decrementHttp('sendReceipt'));
};

export default sendReceipt;
