import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import currency from 'currency.js';

// Views
import SendReceipt from './views/send-receipt';

// Components
import LineItem from './components/lineItem';
import Button from 'components/button';

// Styles
import './receiptStyles.scss';

const FORMATTED = {
  ['PATIENT_ACCOUNT']: 'Patient Account',
  ['CASH']: 'Cash',
  ['CHECK']: 'Check',
  ['CARD']: 'Credit/Debit Card',
  ['PHARMACY_HANDLED']: 'Handled by Pharmacy',
};

const MONTH = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December',
];


const ReceiptView = ({
  receipt,
  isSendingReceipt,
  setIsSendingReceipt,
  onPrintReceipt,
  onClose,
}) => {
  const forMail = useMemo(() => {
    return (receipt?.fulfillment?.prescriptionFills || []).filter(({ fulfillmentType }) => fulfillmentType === 'MAIL_ORDER');
  }, [receipt?.fulfillment?.prescriptionFills]);

  let forDispense = useMemo(() => {
    return (receipt?.fulfillment?.prescriptionFills || []).filter(({ fulfillmentType }) => fulfillmentType === 'DISPENSE');
  }, [receipt?.fulfillment?.prescriptionFills]);

  const payment = receipt?.payment;
  const paymentType = FORMATTED[payment?.type] || payment?.type || 'Unknown';
  const paymentDateAsDate = new Date(payment?.createdAt || '');
  const paymentDate = `${MONTH[paymentDateAsDate.getMonth()]} ${paymentDateAsDate.getDate()}, ${paymentDateAsDate.getFullYear()}`;

  const patientName = [receipt?.patient.firstName, receipt?.patient.lastName].filter(Boolean).join(' ');

  const subTotal = currency(receipt?.fulfillment?.computed_totalCopay || '0').format();
  const deliveryFee = currency(receipt?.fulfillment?.computed_deliveryFee || '0').format();
  const totalCost = currency(receipt?.fulfillment?.computed_totalCost || '0').format();

  if (isSendingReceipt) return <SendReceipt
    receipt={receipt}
    onClose={() => setIsSendingReceipt(false)}
  />;

  return <div className='c-ReceiptView'>
    <div className='content'>
      <div className='actions-area'>
        <Button
          onClick={onPrintReceipt}
        >
          Print Receipt
        </Button>
        <Button
          onClick={ () => setIsSendingReceipt(true) }
        >
          Email Receipt
        </Button>
      </div>
      <h3 className='mega-title'>Receipt</h3>
      <div className='columned-data'>
        <div className='column'>
          <div className='column-item' style={{ borderTop: 'none', paddingTop: '0' }}>
            <div className='item-key'>Dispensing Location</div>
            <div>{receipt.healthcareSite.name || receipt.healthcareSite.location.title || ''}</div>
            <div>{receipt.healthcareSite.location.street1}</div>
            <div>{receipt.healthcareSite.location.city}, {receipt.healthcareSite.location.state} {receipt.healthcareSite.location.zip}</div>
            <div>{receipt.healthcareSite.phoneNumber}</div>
          </div>
        </div>
        <div className='column'>
          <div className='column-item' style={{ borderTop: 'none', paddingTop: '0' }}>
            <div className='item-key'>Pharmacy</div>
            <div>{receipt.pharmacy.name || receipt.pharmacy.location.title || ''}</div>
            <div>{receipt.pharmacy.location.street1}</div>
            <div>{receipt.pharmacy.location.city}, {receipt.pharmacy.location.state} {receipt.pharmacy.location.zip}</div>
            <div>{ receipt.pharmacy.phoneNumber }</div>
          </div>
        </div>
      </div>
      <div className='columned-data' style={{ marginTop: '0px' }}>
        <div className='column'>
          <div className='column-item'>
            <div><span className='item-key'>Patient:</span> { patientName }</div>
          </div>
        </div>
      </div>
      {
        forDispense.map((prescriptionFill) => (
          <LineItem
            key={prescriptionFill.id}
            type='Dispense'
            prescriptionFill={prescriptionFill}
          />
        ))
      }
      {
        forMail.map((prescriptionFill) => (
          <LineItem
            key={prescriptionFill.id}
            type='Mail Order'
            prescriptionFill={prescriptionFill}
          />
        ))
      }
      <div className='costs-summary'>
        <div className='cost-summary-element'>
          <div className='key'>Subtotal</div>
          <div className='value'>{subTotal}</div>
        </div>
        {
          (forMail.length > 0) && <div className='cost-summary-element'>
            <div className='key'>Delivery</div>
            <div className='value'>{deliveryFee}</div>
          </div>
        }
        <div className='cost-summary-element bold'>
          <div className='key'>Total Due</div>
          <div className='value'>{totalCost}</div>
        </div>
      </div>
      <div className='columned-data'>
        <div className='column'>
          <div className='column-item'>
            <div><span className='item-key'>Fulfillment ID:</span> { receipt?.fulfillment.id }</div>
          </div>
        </div>
      </div>
      <div className='columned-data'>
        <div className='column'>
          <div className='column-item'>
            <div><span className='item-key'>Payment Type:</span> { paymentType }</div>
          </div>
        </div>
      </div>
      <div className='columned-data'>
        <div className='column'>
          <div className='column-item'>
            <div><span className='item-key'>Payment Date:</span> { paymentDate }</div>
          </div>
        </div>
      </div>
    </div>
    <div className='actions-area'>
      <Button
        color='red'
        onClick={onClose}
      >
        Back
      </Button>
    </div>
  </div>;
};

export default observer(ReceiptView);
