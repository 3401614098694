import stores from 'bootstrap/store';
import { dispenseAttemptsRouteStore } from '../domain/store';
const dispenseAttemptsStore = stores.global.dispenseAttempts;

export default ({ actingPharmacistId }) => {
  const { activeDispenseAttempt } = dispenseAttemptsRouteStore;
  if (!activeDispenseAttempt) return;
  if (!activeDispenseAttempt.id) return;

  return dispenseAttemptsStore.approve({
    params: { dispenseAttemptId: activeDispenseAttempt.id },
    body: { actingPharmacistId },
  });
};
