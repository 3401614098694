import { NOTIFICATIONS_DATA, NOTIFICATION_TYPES } from '../constants';

const playNotificationSound = (notificationType) => {
  if (!NOTIFICATION_TYPES.includes(notificationType)) {
    return;
  }

  const notificationData = NOTIFICATIONS_DATA[notificationType];
  if (!notificationData || !notificationData.sound?.single) {
    return;
  }

  return notificationData?.sound?.single?.play();
};

export default playNotificationSound;
