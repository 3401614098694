import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import orderBy from 'lodash/orderBy';

import formatPhoneNumber from 'utils/formatPhoneNumber';

// Hooks
import useImage from 'hooks/useImage';

// Components
import Button from 'components/button';
import Receipt from './components/receipt';
import ContentScreen from 'components/content-screen';

// Styles
import './prescriptionFulfillmentStyles.scss';



const FULFILLMENT_TYPE_REMAP = {
  'DECLINE': <div className='token red-token'>DECLINED</div>,
  'DISPENSE': <div className='token blue-token'>KIOSK PICKUP</div>,
  'MAIL_ORDER': <div className='token purple-token'>MAIL DELIVERY</div>,
};

const PAYMENT_TYPE_REMAP = {
  'CASH': <h4 className='blue-text'>CASH</h4>,
  'CARD': <h4 className='blue-text'>DEBIT/CREDIT CARD</h4>,
  'PATIENT_ACCOUNT': <h4 className='blue-text'>PATIENT ACCOUNT</h4>,
};

function FulfillmentDetails({
  store,
  reset,
  saveFillEdits,
}) {
  const fulfillment = store.prescriptionFillsFulfillment?.fulfillment;
  const fulfillmentDate = (() => {
    if (!fulfillment) return 'Loading';
    const date = new Date(fulfillment.createdAt);
    return date.toLocaleDateString();
  })();

  const patientName = [
    store.prescriptionFillsFulfillment?.patient?.firstName,
    store.prescriptionFillsFulfillment?.patient?.lastName,
  ].filter(Boolean).join(' ') || 'Loading';

  const patientDOB = store.prescriptionFillsFulfillment?.patient?.DOB || 'Loading';
  const patientEmail = store.prescriptionFillsFulfillment?.patient?.email;
  const patientPhone = store.prescriptionFillsFulfillment?.patient?.phoneNumber ? formatPhoneNumber(store.prescriptionFillsFulfillment?.patient?.phoneNumber) : '';
  console.log('FULFILLMENT', store.prescriptionFillsFulfillment);
  console.log('PAYMENT', store.prescriptionFillsFulfillment?.payment);

  const patientSignatureLoaded = useImage(store.patientSignatureUrl);

  const fills = orderBy(fulfillment?.prescriptionFills || [], ['createdAt'], ['asc']);
  const multipleFills = fills.length > 1;

  const toggleActionText = store.isEditingPrescriptions ? 'Cancel' : 'Modify Price';

  const healthcareSiteState = store.prescriptionFillsFulfillment?.healthcareSite?.location?.state || 'your state';

  const consultation = useMemo(() => {
    const boxStyle = { display: 'flex', flex: '1 1 auto', flexDirection: 'column', border: 'solid 1px #CCCCCC', borderRadius: '3px', padding: '4px 10px'};

    const consultingPharmacist = fulfillment?.pffVideoConference?.videoConference?.pharmacist;
    const consultingPharmacistName = [ consultingPharmacist?.firstName, consultingPharmacist?.lastName ].filter(Boolean).join(' ');

    const offer = (() => {
      const reviewingPharmacist = fills[0]?.dispenseAttempts[0]?.review?.pharmacist;
      const reviewingPharmacistName = [ reviewingPharmacist?.firstName, reviewingPharmacist?.lastName ].filter(Boolean).join(' ');
      // If a consultation took place, we say that the pharmacist offered the consultation. If there was
      // no consultation, we fall back to the pharmacist who reviewed the prescription as the one who offered
      const offerPharmacistName = consultingPharmacistName || reviewingPharmacistName;
      if (!offerPharmacistName) return '';
      return <h4>Offered by { offerPharmacistName }</h4>;
    })();

    const result = (() => {
      // Check for consulting pharmacist, not just video conference, because video conference could've been initiated by
      // patient but never answered by pharmacist
      if (!consultingPharmacist) {
        if (fulfillment?.consultationDeclined !== true) return <h4><span className='blue-text'>Pending</span></h4>;
        return <h4><span className='red-text'>Patient Declined</span></h4>;
      }
      return <h4><span className='green-text'>Took Place</span> at { (new Date(fulfillment?.pffVideoConference.createdAt)).toLocaleString() }</h4>;
    })();

    return <>
      { offer }
      { result }
    </>;
  }, [fulfillment?.pffVideoConference?.videoConference?.pharmacist, fulfillment?.consultationDeclined, fills]);

  const payment = store.prescriptionFillsFulfillment?.payment;

  const paymentStatus = payment?.computed_successful
    ? <h4 className='green-text'>SUCCESSFUL</h4>
    : <h4 className='blue-text'>PENDING</h4>;

  const paymentAmount = payment?.amount || '0.00';

  const paymentType = PAYMENT_TYPE_REMAP[payment?.type] || 'N/A';

  return <div className='fulfillment-details'>
    <ContentScreen active={store.isBusy} fadeDuration={300} />
    <div className='content'>
      <div className='header'>
        <div className='content-area' style={{ flex: '3 1 0px' }}>
          <h2>{patientName}</h2>
          <div>DOB: {patientDOB}</div>
          <>{ patientEmail && <div>{patientEmail}</div> }</>
          <>{ patientPhone && <div>{patientPhone}</div> }</>
        </div>
        <div className='content-area' style={{ flex: '2 1 0px', alignItems: 'center' }}>
          <h3>Status: { fulfillment?.computed_filled ? 'FULFILLED' : 'IN PROGRESS' }</h3>
        </div>
        <div className='content-area' style={{ flex: '3 1 0px', alignItems: 'flex-end'}}>
          <h3>{fulfillmentDate}</h3>
          <>{ fulfillment ? <h3>Total: ${fulfillment?.computed_totalCost}</h3> : 'Loading' }</>
        </div>
      </div>
      <div className='row-area' style={{ marginTop: '24px' }}>
        <div className='column-area' style={{ flex: '1 1 0px' }}>
          <div className='signature-area'>
            <h3>Patient Signature</h3>
            <div className='signature-container'>
              {
                store.patientSignatureUrl 
                  ? <>
                    <ContentScreen active={!patientSignatureLoaded} fadeDuration={300} />
                    <div className='statement'>I acknowledge that I have received my prescription(s) and have been offered consultation in accordance with { healthcareSiteState } regulations.</div>
                    <img className='signature-image' src={store.patientSignatureUrl} alt='Patient Signature' />
                  </>
                  : <h4 style={{ alignSelf: 'flex-start', justifySelf: 'flex-start' }}><span className='blue-text'>Pending</span></h4>
              }
            </div>
          </div>
        </div>
        <div className='column-area' style={{ flex: '1 1 0px' }}>
          {
            consultation &&
            <div className='consultation-area'>
              <h3>Consultation</h3>
              <div className='consultation-container'>
                { consultation }
              </div>
            </div>
          }
        </div>
      </div>
      
      <section>
        <h2>Prescription{ multipleFills ? 's' : '' }</h2>
        <div className='actions-area' style={{ justifyContent: 'flex-end', marginTop: '-48px' }}>
          {
            store.isEditingPrescriptions &&
            <Button
              onClick={ saveFillEdits }
              disabled={ !store.anyFillEdits || store.isBusy }
              color={ 'green' }
            >
              Save
            </Button>
          }
          {
            fulfillment?.computed_filled &&
            <Button
              onClick={ store.toggleIsEditingPrescriptions }
              disabled={ !fulfillment || store.isBusy }
              color={ 'blue' }
            >
              { toggleActionText }
            </Button>
          }
        </div>
        <div className='prescription-fills-area'>
          <ContentScreen active={!!store.http.updateFills} fadeDuration={300} />
          {
            fills.map((prescriptionFill) => {
              const type = FULFILLMENT_TYPE_REMAP[prescriptionFill?.fulfillmentType] || '';
              const dispenseAttempt = prescriptionFill?.dispenseAttempts[0];
              const review = dispenseAttempt?.review;
              const approved = (review?.approvePrescription ? 'Approved' : 'Rejected');
              const pharmacist = [review?.pharmacist?.firstName, review?.pharmacist?.lastName].filter(Boolean).join(' ');
              const reviewText = review
                ? pharmacist
                  ? <><span className='green-text'>{approved}</span>: {pharmacist}</>
                  : `${review}`
                : 'Review Pending';

              const quantity = dispenseAttempt?.container?.quantity || prescriptionFill.prescription?.drug?.quantity || 'N/A';

              const copay = (() => {
                if (store.isEditingPrescriptions) return <div className='' style={{ display: 'flex'}}>
                  <div style={{ marginRight: '4px'}}>$</div>
                  <input
                    value={store.fillEditById(prescriptionFill.id, 'copay') || ''}
                    style={{ width: '75px', textAlign: 'right', padding: '4px 8px', border: 'solid 1px #CCCCCC', borderRadius: '3px', fontSize: '16px'}}
                    type='text'
                    onChange={ (e) => store.setFillEdit(prescriptionFill.id, 'copay', e.target.value) }
                    onBlur={ () => store.cleanUpFillEdits() }
                  />
                </div>; 

                return prescriptionFill.copay
                  ? <span className={ prescriptionFill.fulfillmentType === 'DECLINE' ? 'strikethrough' : ''}>${ prescriptionFill.copay }</span>
                  : '';
              })();

              return <div className='prescription-fill-container' key={prescriptionFill.id}>
                <div className='content-area'>
                  <h3>{prescriptionFill.prescription?.drug?.name}</h3>
                  <div className='content-row-container'>
                    <div style={{ fontWeight: '600' }}>{prescriptionFill.prescription?.drug?.NDC}</div>
                    <div style={{ fontWeight: '600' }}>QTY: {quantity}</div>
                    <h4 style={{ flex: '1 1 auto'}}><span>RX ID: </span>{ prescriptionFill.prescription.rxId }</h4>
                  </div>
                  <div className='content-row-container' style={{ marginTop: '6px', alignItems: 'flex-end' }}>
                    <>{ type }</>
                    <h4>{ dispenseAttempt?.kiosk?.name }</h4>
                  </div>
                  <div className=''>
                    { reviewText }
                  </div>
                  
                </div>
                <div className='content-area'></div>
                <div className='content-area' style={{ alignItems: 'flex-end'}}>
                  <h3>{ copay }</h3>
                  {
                    !!(prescriptionFill?.priceHistory?.length > 1) &&
                    <div className='price-history'>
                      <h4 style={{ marginBottom: '8px' }}>PRICE HISTORY</h4>
                      {
                        (prescriptionFill.priceHistory).map(({ price, timestamp, pharmacist}) => {
                          return <div className='price-history-entry' key={timestamp}>
                            <div className='info'>
                              <div className='name'> { pharmacist ? `${pharmacist.firstName} ${pharmacist.lastName}` : 'Pharmacy' }</div>
                              <div className='date'>{ (new Date(timestamp)).toLocaleString() }</div>
                            </div>
                            <div className='price'>${ price }</div>
                          </div>;
                        })
                      }
                    </div>
                  }
                </div>
              </div>;
            })
          }
        </div>
      </section>
      
      <section className='payment-area'>
        <h2>Payment History</h2>
        <div className='payment-entry'>
          <div className='content-area'>
            <h3>Payment</h3>
            <>{ paymentStatus }</>
          </div>
          <div className='content-area'>
          
          </div>
          <div className='content-area' style={{ alignItems: 'flex-end'}}>
            <h3>${ paymentAmount }</h3>
            { paymentType }
          </div>
        </div>
        {
          (payment?.paymentModifications || []).map((modification) => {
            const modificationStatus = modification?.computed_successful
              ? <h4 className='green-text'>SUCCESSFUL</h4>
              : <div style={{ display: 'flex'}}><h4 className='blue-text' style={{ marginRight: '8px' }}>PENDING</h4> (scheduled for tonight)</div>;

            const modificationType = modification.amount?.startsWith('-')
              ? <h4 className='red-text'>REFUND</h4>
              : <h4 className='green-text'>CHARGE</h4>;

            return <div className='payment-entry' key={modification.id} style={{ marginTop: '12px' }}>
              <div className='content-area'>
                <h3>Payment Modification</h3>
                <>{ modificationStatus }</>
              </div>
              <div className='content-area'>
              
              </div>
              <div className='content-area' style={{ alignItems: 'flex-end'}}>
                <h3>${ modification.amount }</h3>
                { modificationType }
              </div>
            </div>;
          })
        }
      </section>
        
      <div className='actions-area'>
        <Button
          onClick={() => store.setValue('isShowingReceipt', true)}
          disabled={ !fulfillment || store.isBusy }
        >
          View Receipt
        </Button>
      </div>
    </div>
    <div className='footer-area'>
      <Button
        color='red'
        onClick={ reset }
      >
        Back
      </Button>
    </div>
  </div>;
}

const FulfillmentDetailsObserved = observer(FulfillmentDetails);

function PrescriptionFulfillmentView({
  store,
  reset,
  saveFillEdits
}) {
  return <Modal
    overlayClassName='ModalOverlay'
    className='c-PrescriptionFulfillmentView'
    isOpen={!!store.prescriptionFillsFulfillmentId}
    shouldCloseOnOverlayClick={true}
    style={{ overlay: { zIndex: 9999 } }}
    onRequestClose={ reset }
  >
    {
      !store.isShowingReceipt
        ? <FulfillmentDetailsObserved
          store={store}
          reset={reset}
          saveFillEdits={saveFillEdits}
        />
        : <Receipt
          receipt={ store.prescriptionFillsFulfillment }
          onPrintReceipt={ window.print }
          onRequestReload={ () => store.loadFulfillmentAsReceipt() }
          onClose={ () => store.setValue('isShowingReceipt', false) }
        />
    }
  </Modal>;
}

export default observer(PrescriptionFulfillmentView);
