export default function parseGS1Values(str) {
  const { NDC } = (str.match(/(?:^|Control])01003(?<NDC>\d{10})\d/)?.groups || {});
  const { year, month, day } = (str.match(/(?:^|Control])17(?<year>\d{2})(?<month>(?:1[0-2])|(?:0[1-9]))(?<day>(?:[0-2]\d)|(3[0-1]))/)?.groups) || {};
  const { lotNumber } = (str.match(/(?:^|Control]|17\d{6}|(?:(?:^|Control])01003\d{11}))10(?<lotNumber>(?:(?!Control\]).){1,20})/)?.groups) || {};

  return {
    NDC: NDC || null,
    expirationDate: (month && day && year) ? `${month}/${day == '00' ? '01' : day}/${year}` : null,
    lotNumber: lotNumber || null,
  };
}
