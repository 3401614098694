import React from 'react';
import { observer } from 'mobx-react';

// Components
import SmartTable from 'components/smart-table';
import Threshold from './components/threshold';

// Constants
import { TYPE } from 'models/kiosk-drug-thresholds/constants';

// Styles
import './inventory-table-styles.scss';


const InventoryTable = ({
  kioskInventoryRouteStore,
  rows = [],
  sortInventory,
  setInventoryThreshold,
}) => {
  return <SmartTable
    customClassName='c-InventoryTable'
    sortingFieldName={kioskInventoryRouteStore.sortingColumn.fieldName}
    sortingOrder={kioskInventoryRouteStore.sortingColumn.sortingOrder}
    onSortIconClick={sortInventory}
    rows={rows}
    getRowId={(row = {}) => `${row.id}-${row.NDC}-${row.quantity}`}
    columns={[{
      name: 'Drug',
      fieldName: 'name',
      isSortable: true,
      width: '85%',
      render: ({ content }) => {
        const strengthText = content.strength ? ` (${content.strength})` : '';
        return <div>
          <div title={content.name} className='drug-name'>
            { content.name }{ strengthText }
          </div>
          <div>
            <span className='NDC'>
              NDC:
            </span>
            {
              content.NDC
            }
            <span className='quantity'>
              QTY:
            </span>
            {
              content.quantity || 'N/A'
            }
          </div>
        </div>;
      },
    },
    {
      name: 'Available',
      fieldName: 'count.assignable',
      isSortable: true,
      headerAlign: 'right',
      width: '12%',
      render: ({ content }) => (
        <div title={content.count.assignable} className='numbered-item'>
          {
            content.count.assignable
          }
        </div>
      )
    },
    // {
    //   name: 'Unassignable',
    //   fieldName: 'count.unassignable',
    //   isSortable: true,
    //   width: '15%',
    //   render: ({ content }) => (
    //     <div title={content.count.unassignable} className='numbered-item'>
    //       {
    //         content.count.unassignable
    //       }
    //     </div>
    //   )
    // },
    // {
    //   name: 'Total',
    //   fieldName: 'count.total',
    //   isSortable: true,
    //   width: '10%',
    //   render: ({ content }) => (
    //     <div className='numbered-item'>
    //       {
    //         content.count.total
    //       }
    //     </div>
    //   )
    // },
    // {
    //   name: 'Low Threshold',
    //   fieldName: 'lowContainersCount',
    //   isSortable: true,
    //   width: '15%',
    //   render: ({ content }) => (
    //     <div title={content.lowContainersCount}>
    //       <Threshold
    //         type={TYPE.LOW}
    //         threshold={content.lowContainersCount}
    //         drugId={content.id}
    //         setInventoryThreshold={setInventoryThreshold}
    //       />
    //     </div>
    //   )
    // },
    // {
    //   name: '',
    //   fieldName: 'isLowStock',
    //   isSortable: false,
    //   width: '0',
    //   render: ({ content }) => (
    //     <div
    //       title={content.isLowStock.toString()}
    //       className={content.isLowStock ? 'threshold-out-of-bounds' : 'threshold-in-bounds'}
    //     >
    //       {
    //         content.isLowStock ? 'Yes' : 'No'
    //       }
    //     </div>
    //   )
    // }
    ]}
  />;
};

export default observer(InventoryTable);
