import PropTypes from 'prop-types';



/******************************************************************************/
/********************************  Constants  *********************************/
/******************************************************************************/

export const SORTING_ORDER_OPTION = {
  ASC: 'asc',
  DESC: 'desc'
};



/******************************************************************************/
/*******************************  Content Types  ******************************/
/******************************************************************************/

export const CONTENT_TYPE = Object.freeze({
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  OBJECT: 'object',
  BOOLEAN: 'boolean'
});

export const CONTENT_TYPE_LIST = Object.freeze(Object.keys(CONTENT_TYPE).map(key => CONTENT_TYPE[key]));



/******************************************************************************/
/**********************************  Events  **********************************/
/******************************************************************************/

export const EVENT = Object.freeze({
  COLUMN_RESIZE: 'column_resize',

  ROW_CLICK: 'row_click',
  CELL_CLICK: 'cell_click'
});

export const EVENT_LIST = Object.freeze(Object.keys(EVENT).map(key => EVENT[key]));



/******************************************************************************/
/********************************  Prop Types  ********************************/
/******************************************************************************/

export const PROP_TYPES = {};

// Columns.
PROP_TYPES.COLUMN = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([
    PropTypes.oneOf(CONTENT_TYPE_LIST),
    PropTypes.object
  ]),
  displayAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fieldName: PropTypes.string,
  isSortable: PropTypes.boolean,
  render: PropTypes.func
}).isRequired;

PROP_TYPES.COLUMNS = PropTypes.arrayOf(PROP_TYPES.COLUMN).isRequired;

// Rows
PROP_TYPES.ROW = PropTypes.object.isRequired;

PROP_TYPES.ROWS = PropTypes.arrayOf(PROP_TYPES.ROW).isRequired;

// Before Row Content
PROP_TYPES.BEFORE = PropTypes.func;

// After Row Content
PROP_TYPES.AFTER = PropTypes.func;

// Event Handlers
PROP_TYPES[EVENT.COLUMN_RESIZE] = PropTypes.func;

PROP_TYPES.ON = PropTypes.func;

PROP_TYPES.SORTING_ORDER_OPTION = PropTypes.oneOf(Array.from(Object.values(SORTING_ORDER_OPTION)));

Object.freeze(PROP_TYPES);



/******************************************************************************/
/*********************************  Defaults  *********************************/
/******************************************************************************/

export const DEFAULT = Object.freeze({
  COLUMN_WIDTH: 125,
  COLUMN_ALIGN: 'left',
});
