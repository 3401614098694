import React from 'react';
import { kebabCase } from 'lodash';

// Styles
import './lineItemStyles.scss';



const TYPE_TAG = {
  ['Dispense']: 'KIOSK PICKUP',
  ['Mail Order']: 'MAIL DELIVERY',
  ['Declined']: 'DECLINED',
};

export default function LineItem({
  type,
  prescriptionFill,
}) {
  console.log('PRESCRIPTION FILL', prescriptionFill);
  const rxId = prescriptionFill.prescription.rxId;
  const NDC = prescriptionFill.prescription?.drug?.NDC || 'N/A';

  // Latest dispense attempt
  const latest = prescriptionFill.dispenseAttempts?.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)[0];

  const quantity = latest?.container?.quantity || prescriptionFill?.prescription?.drug?.quantity || 'N/A';

  const kebabType = kebabCase(type);

  return <div
    className={'c-LineItem'}
  >
    <div className={`line-item-content ${kebabType === 'declined' ? 'strikethrough' : ''}`}>
      <div className='item-row'>
        <div className='item-column'>
          <div className='item-entry emphasize'>
            <div className='item-value'>{ prescriptionFill.prescription.drug.name }</div>
          </div>
        </div>
        <div className='item-column' style={{ flex: '0 0 150px', alignItems: 'flex-end' }}>
          <div className='item-entry emphasize'>
            <div className='item-value'>${ prescriptionFill.copay }</div>
          </div>
        </div>
      </div>
      <div className='item-row'>
        <div className='item-column' style={{ flex: '0 0 auto' }}>
          <div className='item-entry'>
            <div className='item-value'>NDC: { prescriptionFill.prescription.drug.NDC }</div>
          </div>
        </div>
        <div className='item-column' style={{ flex: '0 0 auto '}}>
          <div className='item-entry'>
            <div className='item-value'>QTY: { quantity }</div>
          </div>
        </div>
        <div className='item-column' style={{ flex: '0 0 300px' }}>
          <div className='item-entry'>
            <div className='item-value'>{ TYPE_TAG[type] }</div>
          </div>
        </div>
        <div className='item-column' style={{ flex: '0 0 150px', alignItems: 'flex-end' }}>
          <div className='item-entry'>
            <div className='item-value'>&nbsp;</div>
          </div>
        </div>
      </div>
      <div className='item-row'>
        <div className='item-column' style={{ flex: '0 0 auto' }}>
          <div className='item-entry'>
            <div className='item-value'>RXID: { prescriptionFill.prescription.rxId }</div>
          </div>
        </div>
      </div>
    </div>
  </div>;
}
