import { useEffect, useRef } from 'react';

import useRefState from 'hooks/useRefState';
import loadImage from 'utils/images/loadImage';

export default function useImage(url) {
  const ref = useRef(url);
  ref.current = url; // Keep up to date on every render
  const { value: isLoaded, setValue: setIsLoaded } = useRefState(false);

  useEffect(() => {
    setIsLoaded(false);
    if (!url) return;
    (async () => {
      await loadImage(url);
      setIsLoaded(url === ref.current); // Ensure the image is still the same one we were waiting for
    })();
  }, [url]);

  return isLoaded;
}
