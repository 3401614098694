import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

// Views
import KioskContainersView from './kiosk-containers-view';

// Helpers
import fetchActiveContainers from './helpers/fetchActiveContainers';
import fetchKioskInventory from './helpers/fetchKioskInventory';
import invalidateSelectedContainers from './helpers/invalidateSelectedContainers';
import onContainerSearch from './helpers/onContainerSearch';

// Route store
import { kioskContainersRouteStore } from './domain/store';

// Hooks
import useActivePharmacistPrompt from 'singletons/activePharmacistPrompt/useActivePharmacistPrompt';


const KioskContainersContainer = ({ activeKiosk }) => {
  const { initiatePrompt } = useActivePharmacistPrompt();

  function invalidateContainers(activeKiosk) {
    initiatePrompt({
      timeout: 10_000,
      onIdentify: () => {},
      onPharmacist: ({ pharmacist }) => {
        invalidateSelectedContainers({ activeKiosk, actingPharmacistId: pharmacist.id });
      },
    });
  }

  useEffect(() => {
    fetchActiveContainers(activeKiosk);
    fetchKioskInventory(activeKiosk);

    return () => {
      kioskContainersRouteStore.reset();
    };
  }, []);

  useEffect(() => {
    const { containersById, selectedContainerIds } = kioskContainersRouteStore;
    selectedContainerIds.forEach((selectedContainerId) => {
      if (!containersById[selectedContainerId]) {
        kioskContainersRouteStore.deleteSelectedContainer(selectedContainerId);
      }
    });
  }, [kioskContainersRouteStore.containers.length]);


  return <KioskContainersView
    kioskContainersRouteStore={kioskContainersRouteStore}
    invalidateSelectedContainers={() => invalidateContainers(activeKiosk)}
    onContainerSearch={(e) => onContainerSearch(activeKiosk, e)}
  />;
};

export default observer(KioskContainersContainer);
