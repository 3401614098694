import React from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import PrescriptionFillEditModal from './components/prescription-fills-edit-modal';
import SendReceiptModal from './components/send-receipt-modal';

import AdminTableLayout from 'components/admin-table-layout';
import ContentScreen from 'components/content-screen';
import DatePeriodPicker from 'components/date-period-picker';
import Title from 'components/admin-table-layout/components/title';
import TableHeader from 'components/admin-table-layout/components/table-header';
import ListSearch from 'components/drill-down-layout/components/list/components/list-header/components/list-search';
import TableContent from 'components/admin-table-layout/components/table-content';
import SmartTable from 'components/smart-table';
import PrescriptionFulfillment from 'components/prescription-fulfillment';

import { COLUMNS } from './constants';

import './prescription-fills-styles.scss';

const PrescriptionFillsView = ({
  prescriptionFillsRouteStore,
  onPrescriptionFillsSearch,
  showPrescriptionFillEditModal,
  closePrescriptionFillEditModal,
  showSendReceiptModal,
  closeSendReceiptModal,
  cancelPrescriptionFill,
  loadFillsQuery,
  loadMorePrescriptionFills,
  setActivePrescriptionFillFulfillment,
}) => {
  return (
    <React.Fragment>
      <PrescriptionFillEditModal
        isOpen={prescriptionFillsRouteStore.showingPrescriptionFillEditModal}
        activePrescriptionFill={prescriptionFillsRouteStore.activePrescriptionFill}
        isFulfillmentLocked={!!prescriptionFillsRouteStore.activePrescriptionFill?.prescriptionFillsFulfillment?.computed_locked}
        isPrescriptionFillActiveWithoutDispenseAttempt={
          !!prescriptionFillsRouteStore.activePrescriptionFill?.computed_active &&
          !prescriptionFillsRouteStore.prescriptionFillHasDispenseAttempt(prescriptionFillsRouteStore.activePrescriptionFill)
        }
        onClose={() => {
          closePrescriptionFillEditModal();
          loadFillsQuery.refetch();
        }}
      />
      <SendReceiptModal
        isOpen={prescriptionFillsRouteStore.showingSendReceiptModal}
        prescriptionFillsFulfillmentId={_get(prescriptionFillsRouteStore, 'activePrescriptionFill.prescriptionFillsFulfillmentId', null)}
        patientId={_get(prescriptionFillsRouteStore, 'activePrescriptionFill.prescription.patientId', null)}
        onClose={() => closeSendReceiptModal()}
      />
      <PrescriptionFulfillment
        prescriptionFillsFulfillmentId={prescriptionFillsRouteStore.activePrescriptionFillsFulfillmentId}
        onClose={ () => {
          setActivePrescriptionFillFulfillment(null);
          loadFillsQuery.refetch({ silent: true });
        } }
      />
      <ContentScreen active={prescriptionFillsRouteStore.isBusy}/>
      <AdminTableLayout customClassName='c-PrescriptionFillsManage'>
        <Title
          text='Manage Prescriptions'
        />
        <TableHeader>
          <div className='left-area'>
            {/* <EntriesCount
              count={prescriptionFillsRouteStore.prescriptionFillsById.size}
              caption='Prescriptions'
            /> */}
          </div>
          <div className='right-area'>
            <DatePeriodPicker
              startDate={prescriptionFillsRouteStore.dateRange.start}
              onStartDateChange={(date) => prescriptionFillsRouteStore.setStartDate(date)}
              endDate={prescriptionFillsRouteStore.dateRange.end}
              onEndDateChange={(date) => prescriptionFillsRouteStore.setEndDate(date)}
            />
            <ListSearch
              searchValue={prescriptionFillsRouteStore.filterValue}
              onSearch={onPrescriptionFillsSearch}
            />
          </div>
        </TableHeader>
        <TableContent>
          <SmartTable
            loading={prescriptionFillsRouteStore.loading}
            rows={prescriptionFillsRouteStore.forTable}
            columns={COLUMNS({ showPrescriptionFillEditModal, showSendReceiptModal, cancelPrescriptionFill, setActivePrescriptionFillFulfillment })}
            loadMoreData={() => loadMorePrescriptionFills()}
          />
        </TableContent>
      </AdminTableLayout>
    </React.Fragment>
  );};

export default observer(PrescriptionFillsView);
