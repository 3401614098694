import React, { useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

// Components
import Button from 'components/button';

// Styles
import './labels-view.scss';


function KeyCardIgnoringInput({
  value,
  onChange,
  ignorePattern = /\\/,
  ignoreKey = '\\',
  ...props
}) {
  if (!ignorePattern) throw new Error('ignorePattern is required for KeyCardIgnoringInput');
  const elRef = useRef(null);

  const ignoreKeys = useMemo(() => new Set(ignoreKey ? [ignoreKey] : []), [ignoreKey]);

  const onKeyDownLocal = (event) => {
    const key = event.key;
    if (ignoreKeys.has(key)) elRef.current.blur();

    if (props.onKeyDown) props.onKeyDown(event);
  };

  const onChangeLocal = (event) => {
    const { value } = event.target;
    if (value.match?.(ignorePattern)) return elRef.current.blur();
    onChange(value);
  };

  return <input
    { ...props }
    ref={elRef}
    value={value}
    onChange={onChangeLocal}
    onKeyDown={onKeyDownLocal}
  />;
}

const LabelsView  = ({
  labelsRouteStore,
  setFilterValue,
  drugDetailsQuery,
  warningLabelsQuery,
  drugName,
  // Printer stuff
  activePrinter,
  canPreview,
  previewImage,
  dymoEnvironmentError,
  printLabel,
}) => {
  console.log('DRUG DETAILS QUERY', drugDetailsQuery);
  console.log('WARNING LABELS QUERY', warningLabelsQuery);
  console.log('ACTIVE PRINTER', activePrinter);
  
  return <div className='c-LabelsView'>
    <div className='drugs-list'>
      {
        labelsRouteStore.formularyEntriesList.map((formularyEntry, index) => {
          return <div
            className='formulary-entry'
            key={formularyEntry.NDC}
            onClick={() => labelsRouteStore.setValue('activeNDC', formularyEntry.NDC)}
          >
            <div className='drug-name'>{formularyEntry?.drug?.name}</div>
            <div className='drug-ndc'>NDC: {formularyEntry?.NDC}</div>
          </div>;
        })
      }
    </div>
    <div className='label-construction-area'>
      <h2>{ drugName }</h2>
      <div className='print-area'>
        {
          (dymoEnvironmentError?.length || (canPreview && previewImage)) &&
          <div className='preview-area'>
            {
              !!dymoEnvironmentError?.length && <div className='error-message'>
                { dymoEnvironmentError.map((error, index) => <div key={index}>{ error }</div>) }
              </div>
            }
            {
              canPreview && <div className='preview-image'>
                { previewImage && <img src={previewImage} width='381px' /> }
              </div>
            } 
          </div>
        }
        <div className='printer-area'>
          {
            activePrinter
              ? <div className='printer-details'>
                <h4>Successfully Connected to: { activePrinter?.name }</h4>
              </div>
              : <div>Attempting to connect to printer...</div>
          }
          <div className='print-controls'>
            <div className='input-container' style={{ marginBottom: '8px'}}>
              <label>Label Count:</label>
              <KeyCardIgnoringInput
                className='keycard-ignoring-input'
                value={(labelsRouteStore.labelCount)}
                type='number'
                min={1}
                onChange={(value) => labelsRouteStore.setValue('labelCount', parseInt(value, 10))}
              />
            </div>
            <Button
              onClick={printLabel}
              disabled={!canPreview}
              color='blue'
            >
              Print
            </Button>
          </div>
        </div>
      </div>
      
      <div className='inputs-area'>
        <div className='input-container'>
          <label>Active NDC:</label>
          <KeyCardIgnoringInput
            className='keycard-ignoring-input'
            value={
              (labelsRouteStore.activeNDC || '')
              .replace(/^-+/, '')
              .replace(/-+/g, '-')
              .replace(/[^0-9-]/g, '')
            }
            onChange={(value) => labelsRouteStore.setValue('activeNDC', value?.trim())}
          />
        </div>
        <div className='input-container'>
          <label>Lot Number:</label>
          <KeyCardIgnoringInput
            className='keycard-ignoring-input'
            value={(labelsRouteStore.lotNumber || '')}
            onChange={(value) => labelsRouteStore.setValue('lotNumber', value?.trim())}
          />
        </div>
        <div className='input-container'>
          <label>Drug Quantity:</label>
          <KeyCardIgnoringInput
            className='keycard-ignoring-input'
            value={(labelsRouteStore.drugQuantity || '')}
            onChange={(value) => labelsRouteStore.setValue('drugQuantity', value)}
            onBlur={() => labelsRouteStore.setValue('drugQuantity', labelsRouteStore.drugQuantity?.trim())}
          />
        </div>
        <div className='input-container'>
          <label>Expiration:</label>
          <KeyCardIgnoringInput
            className='keycard-ignoring-input'
            value={(labelsRouteStore.expirationDate || '')}
            onChange={(value) => labelsRouteStore.setExpirationDate(value)}
            onBlur={() => labelsRouteStore.setExpirationDate(labelsRouteStore.expirationDate, true)}
          />
        </div>
      </div>
      <div className='warning-labels'>
        {
          warningLabelsQuery.data?.map((label, index) => {
            return <div
              key={index}
              className={`warning-label ${labelsRouteStore.selectedWarningLabels.includes(label) && 'selected'}`}
              onClick={() => labelsRouteStore.toggleSelectedWarningLabel(label) }
            >
              { label }
            </div>;
          })
        }
      </div>
    </div>
  </div>;
};


export default observer(LabelsView);
