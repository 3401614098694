import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { destroy } from 'mobx-state-tree';

// Store
import prescriptionFulfillmentStore from './domain/store';

// Hooks
import useActivePharmacistPrompt from 'singletons/activePharmacistPrompt/useActivePharmacistPrompt';

// Models
import stores from 'bootstrap/store';

// Views
import PrescriptionFulfillmentView from './prescriptionFulfillmentView';



function PrescriptionFulfillmentContainer({
  prescriptionFillsFulfillmentId,
  onClose,
}) {
  const store = useRef(prescriptionFulfillmentStore.create({
    prescriptionFillsFulfillmentId: prescriptionFillsFulfillmentId || null,
  }));

  useEffect(() => {
    store.current.setInitialState();
    return () => {
      store.current.setInitialState();
      destroy(store.current);
    };
  }, []);

  useEffect(() => {
    (async () => {
      store.current.setValue('prescriptionFillsFulfillment', null);
      store.current.setValue('prescriptionFillsFulfillmentId', prescriptionFillsFulfillmentId || null);

      store.current.loadFulfillmentAsReceipt();
    })();
  }, [prescriptionFillsFulfillmentId]);

  useEffect(() => {
    store.current.setValue('patientSignatureUrl', null);

    (async() => {
      if (!prescriptionFillsFulfillmentId) return;

      const signature = await stores.global.patientSignatures.getByFulfillment({ prescriptionFillsFulfillmentId });

      if (!signature?.patientSignatureUrl) return;

      store.current.setValue('patientSignatureUrl', signature.patientSignatureUrl || null);
    })();
    
  }, [prescriptionFillsFulfillmentId]);

  const { initiatePrompt } = useActivePharmacistPrompt();

  function saveFillEdits() {
    console.log('SAVE FILL EDITS');
    initiatePrompt({
      timeout: 10_000,
      onIdentify: () => {},
      onPharmacist: ({ pharmacist }) => {
        store.current.saveEdits({ actingPharmacistId: pharmacist.id });
      },
    });
  }


  function reset() {
    store.current.setInitialState();
    onClose();
  }

  return <PrescriptionFulfillmentView
    store={store.current}
    reset={reset}
    saveFillEdits={saveFillEdits}
  />;
}

export default observer(PrescriptionFulfillmentContainer);
