import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';

const lineHeight = 6.5;

export default function useWarningLabelsAsImage({
  warnings,
  maxLines,
  width,
}) {
  const elRef = useRef(null);

  useEffect(() => {
    const height = (maxLines * lineHeight) + 4;

    const el = document.createElement('div');
    // HTML2Canvas requires the element to be in the DOM and visible, so
    // we position off screen
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    // Actual styles
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.display = 'flex';
    el.style.flexDirection = 'row';
    el.style.overflow = 'hidden';
    el.style.gap = '2px';
    el.style.fontSize = `${lineHeight}px`;
    el.style.fontWeight = 'bold';
    el.style.lineHeight = `${lineHeight}px`;

    warnings.forEach((warning) => {
      const warningEl = document.createElement('div');
      warningEl.style.flex = '1 1 0px';
      warningEl.style.border = 'solid 1px #CCC';
      warningEl.style.padding = '0px 2px';
      warningEl.innerText = warning;
      el.appendChild(warningEl);
    });

    document.body.appendChild(el);
    elRef.current = el;

    // Cleanup on unmount
    return () => {
      document.body.removeChild(el);
    };
  }, [ warnings.join(','), maxLines, width ]);

  return {
    outputAsImage: async () => {
      if (!elRef.current) return null;
      const canvas = await html2canvas(elRef.current, {
        scale: 2,
      });
      return canvas.toDataURL('image/png');
    },
  };
}

// function placeText({ warnings, maxLines, width }) {
//   const element = document.createElement('div');
//   element.style.width = `${width}px`;
//   element.style.position = 'absolute';
//   element.style.top = `${position.y}px`;
//   element.style.left = `${position.x}px`;
//   element.style.display = 'flex';
//   element.style.flexDirection = 'column';
//   element.style.overflow = 'hidden';
//   element.style.maxHeight = `${maxLines * lineHeight}px`; // Assume lineHeight is defined
//   element.innerText = text;

//   document.body.appendChild(element);

//   html2canvas(element).then((canvas) => {
//     const imgData = canvas.toDataURL('image/png');
//     // Use the image data as needed
//     document.body.removeChild(element); // Clean up
//   });
// }

