import { useRef } from 'react';

export default (fn) => {
  const ref = useRef(fn);
  ref.current = fn;

  // Not necessary to wrap this, just prefer to have a stable reference
  const wrapper = useRef((...args) => {
    return ref.current(...args);
  });

  return wrapper.current;
};
