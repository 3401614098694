import React, { useEffect, useRef } from 'react';

import { activePharmacistPromptStore } from './domain';

export default function useActivePharmacistPrompt() {
  const timeout = useRef(null);
  const unlisten = useRef(null);

  function reset() {
    if (timeout.current) clearTimeout(timeout.current);
    if (unlisten.current) unlisten.current();
  }

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return {
    initiatePrompt: ({
      timeout: timeoutValue = 10_000,
      onIdentify,
      onPharmacist,
    } = {}) => {
      reset();

      const { promptId, unlisten: localUnlisten } = activePharmacistPromptStore.listen(
        {
          onIdentify,
          onPharmacist,
        },
      );

      unlisten.current = localUnlisten;

      timeout.current = setTimeout(() => {
        // If we have an identifier, assume pharmacist is being fetched. Eschew unlisten, as will be handled by the store
        if (activePharmacistPromptStore.identifier) return;
        localUnlisten();
      }, timeoutValue);
    },
  };
}
