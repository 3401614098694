import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// Views
import LabelsView from './labels-view';

// Helpers
import * as helpers from './helpers';

// Hooks
import useDebouncedValue from 'hooks/useDebouncedValue';
import usePrevious from 'hooks/usePrevious';
import useWarningLabelsAsImage from './hooks/useWarningLabelsAsImage';
import useDymo from './hooks/useDymo';
import useKeySequenceListener from 'hooks/useKeySequenceListener';

// Stores
import stores from 'bootstrap/store';
import { labelsRouteStore } from './domain/store';
import getLabelXml from './helpers/getLabelXml';
import getLabelSetXml from './helpers/getLabelSetXml';
import formatNDC from 'utils/formatNDC';


const LabelsContainer = () => {
  const queryClient = useQueryClient();

  const debouncedFilterValue = useDebouncedValue(labelsRouteStore.filterValue, 400);
  const previousFilterValue = usePrevious(debouncedFilterValue);

  const [previewImage, setPreviewImage] = useState(null);
  const [warningLabelsImage, setWarningLabelsImage] = useState(null);

  const {
    initialized: dymoInitialized,
    environmentError,
    activePrinter,
    renderLabel,
    printLabel: printLabelCommand,
  } = useDymo();

  const { outputAsImage } = useWarningLabelsAsImage({
    warnings: labelsRouteStore.selectedWarningLabels,
    width: 330,
    maxLines: 6,
  });

  const drugDetailsQuery = useQuery({
    queryKey: ['drugDetails', labelsRouteStore.activeNDC],
    queryFn: ({ signal }) => {
      return stores.global.drugs.getDrugDetails({ params: { NDC: labelsRouteStore.activeNDC }, signal });
    },
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    enabled: !!labelsRouteStore.activeNDC,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const listFormularyEntriesQuery = useQuery({
    queryKey: ['listFormularyEntries', debouncedFilterValue],
    queryFn: ({ signal }) => {
      labelsRouteStore.incrementHttp('listingFormularyEntries');
      return helpers.listFormularyEntries({
        filter: debouncedFilterValue,
        refetch: true,
        merge: false,
        signal,
        controlledAbort: true,
      })
      .finally(() => {
        labelsRouteStore.decrementHttp('listingFormularyEntries');
      });
    },
    staleTime: 0,
    enabled: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: 1000 * 60, // 1 minute
  });

  const warningLabelsQuery = useQuery({
    queryKey: ['warningLabels', labelsRouteStore.activeNDC],
    queryFn: async ({ signal }) => {
      return helpers.getDrugWarningLabels({ NDC: labelsRouteStore.activeNDC, signal });
    },
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    enabled: !!labelsRouteStore.activeNDC,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const prefetchWarningLabels = async (NDC) => {
    await queryClient.prefetchQuery({
      queryKey: ['warningLabels', NDC],
      queryFn: ({ signal }) => helpers.getDrugWarningLabels({ NDC, signal }),
    });
  };

  const {
    match: gs1KeyCard,
    reset: resetKeyCardListener,
  } = useKeySequenceListener({
    sequence: {
      pattern: '\\\\(.+)\\\\',
      maxLength: 3116, // GS1 DataMatrix max length
    },
    allowInputFocus: true,
    formatMatch: (match) => match[1],
    onMatch: (gs1KeyCard) => labelsRouteStore.setValue('gs1DataMatrix', gs1KeyCard),
  });

  useEffect(() => {
    if (!labelsRouteStore.gs1DataMatrix) return;
    labelsRouteStore.clearLabel();

    let match;

    // Check for UPC
    match = labelsRouteStore.gs1DataMatrix.match(/^\d(?<NDC>\d{10})\d$/);
    if (match) {
      const NDCFromUPC = match.groups.NDC;
      labelsRouteStore.setValue('activeNDC', NDCFromUPC);
      return;
    }

    const { NDC, expirationDate, lotNumber} = helpers.containerValuesFromGS1(labelsRouteStore.gs1DataMatrix);
    if (NDC) {
      labelsRouteStore.setValue('activeNDC', NDC);
      if (expirationDate) labelsRouteStore.setExpirationDate(expirationDate, true);
      if (lotNumber) labelsRouteStore.setValue('lotNumber', lotNumber);
    }

    console.log('Set values', labelsRouteStore.activeNDC, labelsRouteStore.expirationDate, labelsRouteStore.lotNumber);
  }, [labelsRouteStore.gs1DataMatrix]);

  console.log('gs1KeyCard', gs1KeyCard);

  // TODO consider removing this if we're charged per call and can't cache
  useEffect(() => {
    console.log('Prefetching warning labels for formulary entries');
    labelsRouteStore.formularyEntriesList.forEach((formularyEntry) => {
      prefetchWarningLabels(formularyEntry.NDC);
    });
  }, [labelsRouteStore.formularyEntriesList.map((formularyEntry) => formularyEntry.NDC).sort().join(',')]);

  useEffect(() => {
    labelsRouteStore.setSelectedWarningLabels([]);
    labelsRouteStore.setValue('gs1DataMatrix', null);
    resetKeyCardListener();
  }, [labelsRouteStore.activeNDC]);

  useEffect(() => {
    labelsRouteStore.setSelectedWarningLabels(warningLabelsQuery.data || []);
  }, [(warningLabelsQuery.data || []).slice().sort().join(',')]);

  const drugName = drugDetailsQuery.data?.medispan?.results?.[0]?.name ||
                   drugDetailsQuery.data?.db?.name ||
                   drugDetailsQuery.data?.db?.brandName ||
                   labelsRouteStore.formularyEntriesByNDC.get(labelsRouteStore.activeNDC)?.drug?.name ||
                   'Unknown Drug';

  useEffect(() => {
    const drugNDC = drugDetailsQuery.data?.db?.NDC || drugDetailsQuery.data?.medispan?.results?.[0]?.ndc;
    if (!drugNDC) return;
    const formattedDrugNDC = formatNDC(drugNDC); // Should already be formatted but just in case
    if (formattedDrugNDC === labelsRouteStore.activeNDC) return;

    // Drug NDC and active NDC are different. What we want to see is if the activeNDC could
    // be the same as the drug, and just requires formatting. If so, we will replace
    // the activeNDC with the formatted drug NDC.
    // First we attempt a straightforward formatting:
    try {
      const formattedNDC = formatNDC(labelsRouteStore.activeNDC);
      if (formattedNDC === formattedDrugNDC) {
        labelsRouteStore.setValue('activeNDC', formattedNDC);
        return;
      }
    }
    // eslint-disable-next-line no-empty
    catch(err) {}
    // If that failed, we see if the activeNDC is 10 digits, which is ambiguous. We will attempt to
    // format it the 3 different ways a 10 digit NDC can be formatted, and see if any of them match
    // the drug NDC.
    if (labelsRouteStore.activeNDC?.match(/^\d{10}$/)) {
      // 10 digit NDC can be interpreted in one of a few ways. It's either
      // 4-4-2, 5-3-2, or 5-4-1. We'll try all three and see if we can find a match.
      const NDCMatches = [
        labelsRouteStore.activeNDC.match(/^(\d{4})(\d{4})(\d{2})/),
        labelsRouteStore.activeNDC.match(/^(\d{5})(\d{3})(\d{2})/),
        labelsRouteStore.activeNDC.match(/^(\d{5})(\d{4})(\d{1})/),
      ];

      const formattedNDCs = [
        `0${NDCMatches[0][1]}-${NDCMatches[0][2]}-${NDCMatches[0][3]}`,
        `${NDCMatches[1][1]}-0${NDCMatches[1][2]}-${NDCMatches[1][3]}`,
        `${NDCMatches[2][1]}-${NDCMatches[2][2]}-0${NDCMatches[2][3]}`,
      ];

      if (formattedNDCs.includes(formattedDrugNDC)) {
        labelsRouteStore.setValue('activeNDC', formattedDrugNDC);
        return;
      }
    }
  }, [labelsRouteStore.activeNDC, drugDetailsQuery.data?.medispan?.results?.[0]?.ndc, drugDetailsQuery.data?.db?.NDC]);

  useEffect(() => {
    if (!activePrinter?.name) return;
    if (!labelsRouteStore.activeNDC) return;
    (async () => {
      const rendered = await renderLabel(getLabelXml({
        drugName: drugName || 'Unknown Drug',
        NDC: labelsRouteStore.activeNDC,
        lotNumber: labelsRouteStore.lotNumber,
        drugQuantity: labelsRouteStore.drugQuantity,
        expirationDate: labelsRouteStore.expirationDate,
        imageData: warningLabelsImage || '',
      }), {}, activePrinter.name);
      // Turn rendered label (base64) into base64 data url
      const base64DataUrl = `data:image/png;base64,${rendered}`;
      setPreviewImage(base64DataUrl);
    })();
  }, [
    activePrinter?.name,
    labelsRouteStore.activeNDC,
    labelsRouteStore.lotNumber,
    labelsRouteStore.expirationDate,
    labelsRouteStore.drugQuantity,
    warningLabelsImage,
    drugName,
  ]);

  useEffect(() => {
    (async () => {
      const imageData = await outputAsImage();
      setWarningLabelsImage((imageData || '').replace(/^data:image\/png;base64,/, ''));
    })();
  }, [labelsRouteStore.selectedWarningLabels.slice().sort().join(',')]);

  const printLabel = useCallback(() => {
    if (!activePrinter?.name) return;
    if (!labelsRouteStore.activeNDC) return;
    labelsRouteStore.formatValues();
    printLabelCommand(
      activePrinter.name,
      {
        copies: labelsRouteStore.labelCount,
      },
      getLabelXml({
        drugName: drugName || 'Unknown Drug',
        NDC: labelsRouteStore.activeNDC,
        lotNumber: labelsRouteStore.lotNumber,
        drugQuantity: labelsRouteStore.drugQuantity,
        expirationDate: labelsRouteStore.expirationDate,
        imageData: warningLabelsImage || '',
      }),
      getLabelSetXml(),
    );
  }, [
    activePrinter?.name,
    labelsRouteStore.activeNDC,
    labelsRouteStore.lotNumber,
    labelsRouteStore.expirationDate,
    labelsRouteStore.drugQuantity,
    warningLabelsImage,
    drugName,
  ]);

  return (
    <LabelsView
      labelsRouteStore={labelsRouteStore}
      drugDetailsQuery={drugDetailsQuery}
      warningLabelsQuery={warningLabelsQuery}
      drugName={drugName}
      canPreview={dymoInitialized && !environmentError}
      previewImage={previewImage}
      dymoEnvironmentError={environmentError}
      activePrinter={activePrinter}
      printLabel={printLabel}
    />
  );
};


export default observer(LabelsContainer);
