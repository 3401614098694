import { useCallback, useEffect, useState } from 'react';
import { dymo } from 'services/dymo-connect';
import { isolate } from 'bunchoid';

/**
 * @typedef {Object} RenderParams
 * @property {Object} [labelColor] - The color of the label.
 * @property {number} [labelColor.a] - The alpha channel of the color (0-255).
 * @property {number} [labelColor.alpha] - Alternative to `a` for the alpha channel of the color (0-255).
 * @property {number} [labelColor.r] - The red channel of the color (0-255).
 * @property {number} [labelColor.red] - Alternative to `r` for the red channel of the color (0-255).
 * @property {number} [labelColor.g] - The green channel of the color (0-255).
 * @property {number} [labelColor.green] - Alternative to `g` for the green channel of the color (0-255).
 * @property {number} [labelColor.b] - The blue channel of the color (0-255).
 * @property {number} [labelColor.blue] - Alternative to `b` for the blue channel of the color (0-255).
 * 
 * @property {Object} [shadowColor] - The color of the label shadow.
 * @property {number} [shadowColor.a] - The alpha channel of the shadow color (0-255).
 * @property {number} [shadowColor.alpha] - Alternative to `a` for the alpha channel of the shadow color (0-255).
 * @property {number} [shadowColor.r] - The red channel of the shadow color (0-255).
 * @property {number} [shadowColor.red] - Alternative to `r` for the red channel of the shadow color (0-255).
 * @property {number} [shadowColor.g] - The green channel of the shadow color (0-255).
 * @property {number} [shadowColor.green] - Alternative to `g` for the green channel of the shadow color (0-255).
 * @property {number} [shadowColor.b] - The blue channel of the shadow color (0-255).
 * @property {number} [shadowColor.blue] - Alternative to `b` for the blue channel of the shadow color (0-255).
 * 
 * @property {number} [shadowDepth=0] - The shadow width in TWIPS. If '0' is specified, no shadow is rendered.
 * @property {string} [flowDirection] - The direction of the label content ('left-to-right' or 'right-to-left'). Use the dymo.label.framework.FlowDirection enumeration.
 * @property {boolean} [pngUseDisplayResolution=false] - If true, the PNG will be generated using the display resolution. If false, the PNG will be generated using the printer resolution.
 */

/**
 * @typedef {Object} LabelWriterPrintParams
 * @property {number} [copies] - Number of copies to print. Default value will be used if not specified.
 * @property {string} [jobTitle] - Print job title/description. Default value will be used if not specified.
 * @property {FlowDirection} [flowDirection] - Direction to print the label content ('left-to-right' or 'right-to-left'). Use the FlowDirection enum.
 * @property {'Text' | 'BarcodeAndGraphics' | 'Auto'} [printQuality] - Printing quality. One of 'Text', 'BarcodeAndGraphics', or 'Auto'. Default value will be used if not specified.
 * @property {'Left' | 'Right' | 'Auto'} [twinTurboRoll] - The roll to print on if the printer is TwinTurbo. One of 'Left', 'Right', or 'Auto'. Default value will be used if not specified.
 */

const bunchoid = isolate();

export default function useDymo() {
  const [initialized, setInitialized] = useState(false);
  const [isServerRunning, setIsServerRunning] = useState(false);
  const [environmentError, setDymoEnvironmentError] = useState(null);
  const [printers, setPrinters] = useState([]);
  const [activePrinter, setActivePrinter] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        await dymo.label.framework.init();
      }
      catch (error) { console.log('Error initializing Dymo', error); }

      let checkEnvironmentResult;
      for (let i = 0; i < 3; i++) {
        checkEnvironmentResult = await dymo.label.framework.checkEnvironment();
        if (!checkEnvironmentResult.isBrowserSupported) break;
        if (checkEnvironmentResult.errorDetails) break;
        if (checkEnvironmentResult.isWebServicePresent) break;
        await new Promise((resolve) => setTimeout(resolve, 500 * i));
      }
      const isServerRunning = checkEnvironmentResult.isWebServicePresent && !checkEnvironmentResult.errorDetails;
      setIsServerRunning(isServerRunning);
      setDymoEnvironmentError(
        checkEnvironmentResult.errorDetails
          ? [`Label Printer Issue: ${checkEnvironmentResult.errorDetails}`, 'Please contact MedifriendRx for assistance setting up your label printer.']
          : null
      );

      if (isServerRunning) {
        console.log('Getting printers');
        let printers = [];
        for (let i = 0; i < 3; i++) {
          try {
            printers = await dymo.label.framework.getLabelWriterPrintersAsync();
            break;
          }
          catch (error) {
            console.log('Error getting printers', error);
          }
          await new Promise((resolve) => setTimeout(resolve, 500 * i));
        }
        console.log('PRINTERS', printers);
        setPrinters(printers || []);
        setActivePrinter(printers[0]);

        if (printers.length === 0) {
          // setDymoEnvironmentError(['No Label Printers Found.', 'Please connect a label printer and try again.']);
        }
      }

      setInitialized(true);
    })();
  }, []);

  /**
   * Renders a label preview.
   * @param {string} labelXml - Label to preview.
   * @param {string | RenderParams} renderParamsXml - The rendering parameters, such as shadow depth, label color, etc. See LabelRenderParams.xsd.
   * @param {string} printerName - The name of the printer that the preview is generated for. The preview/output can be different for different printers, especially for tape printers with different print head sizes. If it is not important what printer the label is printed to, an empty string can be passed . In this case, the default printer metrics will be used. The default is LW400 for LabelWriter printers and LW400 DUO Tape for tape printers.
   */
  const renderLabel = useCallback((labelXml, renderParamsXml, printerName) => {
    if (!renderParamsXml) throw new Error('renderParamsXml is required to render a label.');
    return bunchoid(() => {
      if (typeof renderParamsXml !== 'string') {
        console.log('Got render params as object', renderParamsXml);
        renderParamsXml.pngUseDisplayResolution = renderParamsXml.pngUseDisplayResolution || false;
        renderParamsXml = dymo.label.framework.createLabelRenderParamsXml(renderParamsXml);
        console.log('Converted render params to string', renderParamsXml);
      }

      return dymo.label.framework.renderLabel(labelXml, renderParamsXml, printerName);
    }, {
      key: ['renderLabel'],
      wait: 300,
      maxWait: 1500,
    });
  }, [dymo.label.framework.renderLabel]);


  /**
   * Prints a label.
   * @param {string} printerName - The name of the printer to print on.
   * @param {string | LabelWriterPrintParams} printParamsXml - Printing parameters, like number of copies, print quality, etc. See PrintParams.xsd.
   * @param {string} labelXml - The label to print.
   * @param {string} labelSetXml - LabelSet to print. LabelSet is used to print multiple labels with same layout but different data, e.g. multiple addresses. Use LabelSetBuilder to create a LabelSet or construct xml manually according to LabelSet
   * @return {Promise<string>} - The result of the print operation.
   */
  const printLabel = useCallback(async (printerName, printParamsXml, labelXml, labelSetXml) => {
    if (!printerName) throw new Error('printerName is required to print a label.');
    if (!printParamsXml) printParamsXml = {};
    if (!labelXml) throw new Error('labelXml is required to print a label.');
    if (!labelSetXml) throw new Error('labelSetXml is required to print a label.');

    return bunchoid(
      async () => {
        if (typeof printParamsXml !== 'string') {
          console.log('Got print params as object', printParamsXml);
          printParamsXml.copies = printParamsXml.copies || 1;
          printParamsXml.printQuality = printParamsXml.printQuality || 'Auto';
          // printParamsXml.flowDirection = printParamsXml.flowDirection || 'left-to-right'; // This causes problems
          printParamsXml.twinTurboRoll = printParamsXml.twinTurboRoll || 'Auto';
          printParamsXml = dymo.label.framework.createLabelWriterPrintParamsXml(printParamsXml);
          console.log('Converted print params to string', printParamsXml);
        }
  
        console.log('Printing', printerName);
        console.log('Print Params', printParamsXml);
        console.log('Label', labelXml);
        console.log('Label Set', labelSetXml);
        const result = await dymo.label.framework.printLabelAsync(printerName, printParamsXml, labelXml, labelSetXml);
        console.log('PRINTED', result);
        return result;
      },
      {
        key: ['printLabel'],
        wait: 1000,
        maxWait: 2000,
      },
    );
  }, [dymo.label.framework.print]);

  return {
    dymo: window.dymo,
    initialized,
    isServerRunning,
    environmentError,
    printers,
    activePrinter,
    renderLabel,
    printLabel,
  };
}
