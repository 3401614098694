import React from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react';

import ContentScreen from 'components/content-screen';
import AwayModeModal from './components/away-mode-modal';
import DropDownMenu from './components/drop-down-menu';

import { STATUS } from 'models/pharmacists/constants';

// Style
import './header-view.scss';

const CustomUserOption = ({ innerProps, data }) => {
  return (
    <div className='user-select-option' {...innerProps}>
      <div className='label'>
        {
          data.label
        }
      </div>
      <div className='email'>
        {
          data.user.email
        }
      </div>
    </div>
  );
};


const HeaderView = ({
  activeUser,
  userOptions,
  isActiveUserPharmacist,
  isSettingStatus,
  setPharmacistStatus,
  pharmacistStatus,
  logOut,
  openCameraConfiguration,
  redirectToLogin,
  onUserChange,
}) => (
  <div className='c-HeaderView'>
    <ContentScreen active={isSettingStatus} />
    <AwayModeModal
      isPharmacistAway={pharmacistStatus === STATUS.AWAY}
      isSettingStatus={isSettingStatus}
      setAvailableStatus={() => setPharmacistStatus(STATUS.AVAILABLE)}
    />
    <div className='left-section'>
      <div className='medifriendrx-logo'>
        medifriend<span>Rx</span>
      </div>
    </div>
    <div className='mid-section'>
    </div>
    <div className='right-section'>
      {
        isActiveUserPharmacist &&
        <button className='enter-away-mode-btn' onClick={() => setPharmacistStatus(STATUS.AWAY)}>
          Enter &quot;Away Mode&quot;
        </button>
      }
      {
        activeUser && userOptions.length > 1 &&
        <Select
          className='user-select'
          components={{
            Option: CustomUserOption,
          }}
          searchable={false}
          clearable={false}
          value={{
            value: activeUser.id,
            label: `${activeUser.firstName} ${activeUser.lastName}`,
          }}
          options={userOptions.map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
            user,
          }))}
          onChange={(option) => onUserChange(option.value)}
        />
      }
      <DropDownMenu
        items={[
          // eslint-disable-next-line react/jsx-key
          <div onClick={openCameraConfiguration}>Camera configuration</div>,
          // eslint-disable-next-line react/jsx-key
          <div onClick={redirectToLogin}>Log in another pharmacist</div>,
          // eslint-disable-next-line react/jsx-key
          <div onClick={logOut}>Log Out</div>,
        ]}
      />
    </div>
  </div>
);


export default observer(HeaderView);
