import stores from 'bootstrap/store';
import { lotInfoRouteStore } from '../domain/store';

const createLotDrugRecall = async ({ drugId, actingPharmacistId }) => {
  const { activeLot } = lotInfoRouteStore;
  if (!activeLot || !drugId) {
    return;
  }

  lotInfoRouteStore.incrementHttp('creatingLotDrugRecall');
  return stores.global.lotDrugRecalls.createLotDrugRecall({
    body: {
      lotId: activeLot.id,
      drugId,
      actingPharmacistId,
    },
  })
  .finally(() => lotInfoRouteStore.decrementHttp('creatingLotDrugRecall'));
};

export default createLotDrugRecall;
