import stores from 'bootstrap/store';
import { callRequestsRouteStore } from '../domain/store';

import { ACTION } from 'models/call-request-pharmacist-actions/constants';
import { CALL_REQUESTS_TAB } from '../constants';

import { fetchActivePatientCallRequestById, listCallRequests } from './index';

const createPharmacistAction = async ({ action, actingPharmacistId }) => {
  const { activeCallRequestId } = callRequestsRouteStore;
  if (!activeCallRequestId) {
    return;
  }

  callRequestsRouteStore.incrementHttp('creatingPharmacistAction');
  const pharmacistAction = await stores.global.callRequestPharmacistActions.createPharmacistAction({
    body: {
      callRequestId: activeCallRequestId,
      action,
      actingPharmacistId,
    },
  });
  
  stores.global.callRequests.setValue('dataForPharmacist', {});
  callRequestsRouteStore.setValue('currentPage', 1);
  if (action === ACTION.CONTACT_ATTEMPTED) {
    callRequestsRouteStore.setValue('activeListTab', CALL_REQUESTS_TAB.CONTACTED);
  } else {
    callRequestsRouteStore.setValue('activeListTab', CALL_REQUESTS_TAB.HANDLED);
  }

  await listCallRequests();
  await fetchActivePatientCallRequestById();

  callRequestsRouteStore.decrementHttp('creatingPharmacistAction');

  return pharmacistAction;
};

export default createPharmacistAction;
