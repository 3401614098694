import stores from 'bootstrap/store';
import { kioskContainersRouteStore } from '../domain/store';

import fetchActiveContainers from './fetchActiveContainers';
import fetchKioskInventory from './fetchKioskInventory';

const invalidateSelectedContainers = async ({ activeKiosk, actingPharmacistId }) => {
  const { selectedContainerIds } = kioskContainersRouteStore;
  if (!selectedContainerIds.length) {
    return;
  }

  kioskContainersRouteStore.incrementHttp('invalidatingContainers');

  const invalidateContainerPromises = selectedContainerIds.map((containerId) => {
    return stores.global.containers.invalidate({
      params: { containerId },
      body: { actingPharmacistId },
    });
  });
  await Promise.all(invalidateContainerPromises);

  kioskContainersRouteStore.setValue('selectedContainers', {});

  await Promise.all([
    fetchActiveContainers(activeKiosk),
    fetchKioskInventory(activeKiosk),
  ]);

  kioskContainersRouteStore.decrementHttp('invalidatingContainers');
};

export default invalidateSelectedContainers;
