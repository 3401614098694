import { flow, types } from 'mobx-state-tree';
import {
  get as _get,
  isNil as _isNil,
  keyBy as _keyBy,
  omitBy as _omitBy,
  orderBy as _orderBy,
  reduce as _reduce,
} from 'lodash';

import base from 'models/base';
import { MODEL_NAME, SCHEMA } from './constants/schema';


const PrescriptionFillsFulfillmentModel = base({
  names: {
    singular: MODEL_NAME.SINGULAR,
    plural: MODEL_NAME.PLURAL
  },
  JSONSchema: SCHEMA,
  httpConfig: {
    root: MODEL_NAME.plural,
    methods: {}
  }
});

PrescriptionFillsFulfillmentModel.configureStore((store) => {
  return store
  .props({
    
  })
  .actions(self => ({
    setValue: (prop, value) => self[prop] = value,
    setInitialState: () => {
    },
    getAsReceipt: flow(function* (config = {}) {
      const prescriptionFillsFulfillmentId = _get(config, 'params.prescriptionFillsFulfillmentId');
      if (_isNil(prescriptionFillsFulfillmentId)) throw new Error('prescriptionFillsFulfillmentId is required for getAsReceipt');

      config.urlFragment = () => `${prescriptionFillsFulfillmentId}/receipt`;
      const response = yield self.get(config);

      return response;
    }),
  }))
  .views(self => ({
   
  }));
});

// Can't use module.exports because there is a generator function in this file,
// and that causes module.exports to fail for some reason.
export default PrescriptionFillsFulfillmentModel;
