import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import 'yet-another-abortcontroller-polyfill';

import PrescriptionFillsView from './prescription-fills-view';

import useDebouncedValue from 'hooks/useDebouncedValue';
import usePrevious from 'hooks/usePrevious';
import useSocketEvent from 'hooks/useSocketEvent';

import * as helpers from './helpers';

import { prescriptionFillsRouteStore } from './domain/store';

import { EVENT } from 'bootstrap/api-websocket/constants';

function useArgsQuery({
  queryFn,
  ...props
}) {
  const argsRef = useRef(null);
  
  const queryFnWrapper = (context) => {
    const currentArgs = argsRef.current;
    argsRef.current = null;
    return queryFn({ ...context, args: currentArgs });
  };

  const result = useQuery({
    ...props,
    queryFn: queryFnWrapper,
  });

  return {
    ...result,
    refetch: (...args) => {
      argsRef.current = args;
      return result.refetch();
    },
  };
}

const PrescriptionFillsContainer = () => {
  const queryClient = useQueryClient();
  
  const debouncedFilterValue = useDebouncedValue(prescriptionFillsRouteStore.filterValue, 400);
  const previousFilterValue = usePrevious(debouncedFilterValue);

  useEffect(() => {
    prescriptionFillsRouteStore.setInitialState();

    return () => {
      prescriptionFillsRouteStore.setInitialState();
    };
  }, []);

  useEffect(() => {
    helpers.listPrescriptionFills({
      refetch: Boolean(prescriptionFillsRouteStore.forTable.length),
      merge: false,
    });
  }, [prescriptionFillsRouteStore.dateRange.start, prescriptionFillsRouteStore.dateRange.end]);

  useSocketEvent(EVENT.RECEIVED.PRESCRIPTION_FILLS_SET_FULFILLMENT_TYPE, async ({ prescriptionFillId }) => {
    if (prescriptionFillsRouteStore.prescriptionFillsById.get(prescriptionFillId)) {
      helpers.listPrescriptionFills({ refetch: true });
    }
  });

  useEffect(() => {
    console.log(prescriptionFillsRouteStore.forTable);
  }, [prescriptionFillsRouteStore.forTable.length]);

  const onPrescriptionFillsSearch = useCallback((e) => {
    queryClient.cancelQueries({ queryKey: ['searchPrescriptionFills'] });
    prescriptionFillsRouteStore.setValue('filterValue', e.target.value);
  }, [queryClient]);

  const loadFillsQuery = useArgsQuery({
    queryKey: ['searchPrescriptionFills', debouncedFilterValue],
    queryFn: ({ signal, args }) => {
      console.log('ARGS', args);
      const silent = !!args?.[0]?.silent;
      if (!silent) prescriptionFillsRouteStore.incrementHttp('listing');
      return helpers.listPrescriptionFills({
        filter: debouncedFilterValue,
        refetch: true,
        merge: false,
        signal,
        controlledAbort: true,
      })
      .finally(() => {
        if (!silent) prescriptionFillsRouteStore.decrementHttp('listing');
      });
    },
    staleTime: 0,
    enabled: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: 1000 * 60, // 1 minute
  });

  const loadMorePrescriptionFills = useCallback(async () => {
    return helpers.listPrescriptionFills({
      filter: prescriptionFillsRouteStore.filterValue,
      refetch: false,
      merge: true,
    });
  }, [prescriptionFillsRouteStore.filterValue]);

  function setActivePrescriptionFillFulfillment(fulfillmentId) {
    prescriptionFillsRouteStore.setValue('activePrescriptionFillsFulfillmentId', fulfillmentId);
  }

  return (
    <PrescriptionFillsView
      prescriptionFillsRouteStore={prescriptionFillsRouteStore}
      onPrescriptionFillsSearch={onPrescriptionFillsSearch}
      showPrescriptionFillEditModal={helpers.showPrescriptionFillEditModal}
      closePrescriptionFillEditModal={helpers.closePrescriptionFillEditModal}
      showSendReceiptModal={helpers.showSendReceiptModal}
      closeSendReceiptModal={helpers.closeSendReceiptModal}
      listPrescriptionFills={helpers.listPrescriptionFills}
      cancelPrescriptionFill={helpers.cancelPrescriptionFill}
      loadFillsQuery={loadFillsQuery}
      loadMorePrescriptionFills={loadMorePrescriptionFills}
      setActivePrescriptionFillFulfillment={setActivePrescriptionFillFulfillment}
    />
  );
};

export default observer(PrescriptionFillsContainer);
