import { types } from 'mobx-state-tree';

import doesValueMatchRegex from 'utils/doesValueMatchRegex';
import { EMAIL_RE } from '../constants';

export const SendReceiptModalRouteStore = types.model({
})
.props({
  email: types.optional(types.string, ''),
  shouldSaveEmail: types.optional(types.boolean, false),
  http: types.optional(types.model({
    updatingPatientById: types.optional(types.number, 0),
    sendReceipt: types.optional(types.number, 0),
  }), {}),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  setInitialState: ({
    email,
  } = {}) => {
    self.email = email || '';
    self.shouldSaveEmail = false;
    self.http = {};
  },
}))
.views(self => ({
  get canBeSubmitted() {
    if (!self.email || (self.email && !doesValueMatchRegex(EMAIL_RE, self.email))) {
      return false;
    }
 
    return true;
  },
  get isBusy() {
    return Boolean(self.http.updatingPatientById || self.http.sendReceipt);
  },
}));

export const sendReceiptModalRouteStore = SendReceiptModalRouteStore.create({});
