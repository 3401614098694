import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { get as _get } from 'lodash';

import VideoConferenceNotificationsView from './video-conferences-notifications-view';

import { NOTIFICATION_TYPE } from '../../../../constants';

import { fetchNotificationsRelatedData } from '../../../../helpers';

import stores from 'bootstrap/store';
import { notificationsPaneRouteStore } from '../../../../domain/store';

// Hooks
import useActivePharmacistPrompt from 'singletons/activePharmacistPrompt/useActivePharmacistPrompt';

const videoConferencesStore = stores.global.videoConferences;
const videoConferenceInvitationsStore = stores.global.videoConferenceInvitations;


const VideoConferencesNotificationsContainer = () => {
  const { initiatePrompt } = useActivePharmacistPrompt();
  
  const accept = useCallback((videoConference) => {
    initiatePrompt({
      timeout: 10_000,
      onIdentify: () => {},
      onPharmacist: ({ pharmacist }) => {
        videoConferencesStore.setValue('activePendingVideoConferenceId', videoConference.id);
        videoConferencesStore.setValue('showingVideoConferenceModal', true);
        notificationsPaneRouteStore.close();
      },
    });
  }, []);

  const reject = useCallback(async (videoConference) => {
    const videoConferenceInvitationId = _get(videoConference, 'videoConferenceInvitations[0].id');
    await videoConferenceInvitationsStore.rejectInvitation({ params: { videoConferenceInvitationId } });
    notificationsPaneRouteStore.close();
  }, []);

  useEffect(() => {
    fetchNotificationsRelatedData(NOTIFICATION_TYPE.VIDEO_CONFERENCES);
  }, []);

  return <VideoConferenceNotificationsView
    active={videoConferencesStore.activeConferences}
    pending={videoConferencesStore.pendingInvitations}
    allPendingConferences={videoConferencesStore.pendingConferences}
    isActiveVideoConference={videoConferencesStore.isActiveConference}
    accept={accept}
    reject={reject}
  />;
};

export default observer(VideoConferencesNotificationsContainer);
