import { keyBy as _keyBy } from 'lodash';


import stores from 'bootstrap/store';
import { labelsRouteStore } from '../domain/store';
const formularyEntriesStore = stores.global.formularyEntries;


export default async ({ filter, refetch = false, merge = false, signal, controlledAbort = false } = {}) => {
  const query = {};

  return formularyEntriesStore.list({ query, signal, controlledAbort })
  .then((formularyEntries) => {
    const formularyEntriesByNDC = _keyBy(formularyEntries, 'NDC');
    if (merge) {
      labelsRouteStore.mergeFormularyEntriesByNDC(formularyEntriesByNDC);
    } else {
      labelsRouteStore.setFormularyEntriesByNDC(formularyEntriesByNDC);
    }

    return formularyEntries;
  });
};
