import stores from 'bootstrap/store';
import { sendReceiptModalRouteStore } from '../domain/store';

// Utils
import doesValueMatchRegex from 'utils/doesValueMatchRegex';

// Constants
import { EMAIL_RE } from '../constants';

const updatePatientById = async ({
  patientId,
  email,
}) => {
  if (!patientId || !email) return;
  if (!doesValueMatchRegex(EMAIL_RE, email)) return;

  sendReceiptModalRouteStore.incrementHttp('updatingPatientById');
  return stores.global.patients.updatePatient({
    params: {
      id: patientId,
    },
    body: {
      email,
    },
  })
  .finally(() => sendReceiptModalRouteStore.decrementHttp('updatingPatientById'));
};

export default updatePatientById;
