import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import SendReceiptView from './send-receipt-view';

import { sendReceiptModalRouteStore } from './domain/store';

import sendReceiptHelper from './helpers/sendReceipt';
import updatePatientByIdHelper from './helpers/updatePatientById';

const SendReceiptContainer = ({
  receipt,
  onClose,
}) => {
  useEffect(() => {
    sendReceiptModalRouteStore.setInitialState({
      email: receipt.patient.email,
    });

    return () => sendReceiptModalRouteStore.setInitialState();
  }, []);

  const sendReceipt = useCallback(async () => {
    if (
      sendReceiptModalRouteStore.shouldSaveEmail &&
      sendReceiptModalRouteStore.email !== receipt.patient.email
    ) {
      await updatePatientByIdHelper({
        patientId: receipt.patient.id,
        email: sendReceiptModalRouteStore.email,
      });
    }

    await sendReceiptHelper(receipt.fulfillment.id);
    
    onClose();
  }, []);

  return <SendReceiptView
    sendReceiptModalRouteStore={sendReceiptModalRouteStore}
    patientEmail={receipt.patient.email}
    sendReceipt={sendReceipt}
    onClose={onClose}
  />;
};

export default observer(SendReceiptContainer);
