import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import NavbarView from './navbar-view';

import { constructFullRoute, setActiveTab } from './helpers';

import { isActiveRoute } from 'bootstrap/store/router';

import stores from 'bootstrap/store';
import { navbarRouteStore } from './domain/store';
import { appFrameRouteStore } from '../../domain/store';
const usersStore = stores.global.users;


const NavbarContainer = ({ activeUserAccessibleRoutes }) => {
  const { pathname } = useLocation();

  console.log('activeUserAccessibleRoutes', activeUserAccessibleRoutes);

  useEffect(() => {
    if (!pathname) return;

    const activeRouteParts = pathname.split('/');
    const activeRootRoute = `/${activeRouteParts[1]}`;

    const { activeUserAccessibleRouteByValue } = appFrameRouteStore;
    const activeRootRouteData = activeUserAccessibleRouteByValue[activeRootRoute];
    if (activeRootRouteData) {
      const subRoutes = Object.values(activeRootRouteData.SUB_ROUTE || {});
      const activeFullRoute = subRoutes.find((subRoute) => {
        const fullRoute = constructFullRoute(activeRootRoute, subRoute.VALUE);
        return isActiveRoute(fullRoute);
      });
      if (activeFullRoute) navbarRouteStore.showRouteDropdown(activeRootRoute, true);
    }
  }, [pathname]);

  return <NavbarView
    routeStore={navbarRouteStore}
    activeUserAccessibleRoutes={activeUserAccessibleRoutes}
    activeUser={usersStore.activeUser}
    activeUserTypeTab={appFrameRouteStore.activeUserTypeTab}
    setActiveUserTypeTab={setActiveTab}
    constructFullRoute={constructFullRoute}
  />;
};

export default observer(NavbarContainer);
