import React from 'react';
import Modal from 'react-modal';

import { observer } from 'mobx-react';

import './activePharmacistPromptStyles.scss';

function ActivePharmacistPromptView({ activePharmacistPromptStore, pharmacist }) {
  console.log('View pharmacist', pharmacist);

  const name = (() => {
    return [pharmacist?.pharmacist?.firstName, pharmacist?.pharmacist?.lastName].filter(Boolean).join(' ');
  })();

  const badge = activePharmacistPromptStore.identifier;

  return <Modal
    overlayClassName='ModalOverlay'
    className='c-ActivePharmacistPromptModal'
    isOpen={activePharmacistPromptStore.isVisible}
    shouldCloseOnOverlayClick={true}
    style={{ overlay: { zIndex: 9999999 } }}
    onRequestClose={() => activePharmacistPromptStore.reset()}
  >
    {/* <ContentScreen active={isSettingStatus} /> */}
    <div className='content'>
      {
        activePharmacistPromptStore.error
          ?
          <div className='error'>
            <h2>{ activePharmacistPromptStore.error }</h2>
          </div>
          :
          <>
            {
              !badge &&
              <>
                <h1
                  className='badge-instructions'
                >
                  Identification required.<br/>Please scan badge.
                </h1>
                <button className='cancel-prompt' onClick={() => activePharmacistPromptStore.reset()}>
                  Cancel
                </button>
              </>
            }
            {
              badge && !pharmacist &&
              <>
                <div className='loading'>
                  Identifying...
                </div>
              </>
            }
            {
              pharmacist &&
              <div className='pharmacist-welcome'>
                <h2>{ name } Identified!</h2>
              </div>
            }
          </>
      }
    </div>
  </Modal>;
}

export default observer(ActivePharmacistPromptView);
