import deepFreeze from 'deep-freeze';

export const MODEL_NAME = Object.freeze({
  SINGULAR: 'prescriptionFillsFulfillment',
  PLURAL: 'prescription-fills-fulfillments',
});

export const FIELD_NAME = Object.freeze({
  COMPUTED_FILLED: 'computed_filled',
});

export const SCHEMA = deepFreeze({
  title: MODEL_NAME.SINGULAR,
  type: 'object',
  properties: {
    [FIELD_NAME.COMPUTED_FILLED]: {
      type: 'boolean',
    },
  },
});
