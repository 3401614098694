import { types } from 'mobx-state-tree';
import { sortBy as _sortBy } from 'lodash';

export const LabelsRouteStore = types.model({
})
.props({
  activeNDC: types.maybeNull(types.string),
  lotNumber: types.maybeNull(types.string),
  drugQuantity: types.maybeNull(types.string),
  expirationDate: types.maybeNull(types.string),
  labelCount: types.optional(types.number, 1),
  gs1DataMatrix: types.maybeNull(types.string),
  selectedWarningLabels: types.optional(types.array(types.string), []),
  formularyEntriesByNDC: types.map(types.frozen()),
  http: types.optional(types.model({
    listingFormularyEntries: types.optional(types.number, 0)
  }), {}),
  filterValue: types.optional(types.string, ''),
  currentPage: types.optional(types.number, 1),
})
.actions(self => ({
  setValue: (prop, value) => self[prop] = value,
  incrementHttp: (http) => ++self.http[http],
  decrementHttp: (http) => --self.http[http],
  reset: () => {
    self.kiosks = {};
    self.http = {};
    self.activeFormularyEntryId = null;
    self.filterValue = '';
    self.currentPage = 1;
  },
  mergeFormularyEntriesByNDC: (formularyEntriesByNDC) => self.formularyEntriesByNDC.merge(formularyEntriesByNDC),
  setFormularyEntriesByNDC: (formularyEntriesByNDC) => {
    self.formularyEntriesByNDC = {};
    self.formularyEntriesByNDC = formularyEntriesByNDC;
  },
  clearLabel: () => {
    self.activeNDC = null;
    self.lotNumber = null;
    self.drugQuantity = null;
    self.expirationDate = null;
  },
  setExpirationDate: (date, strict = false) => {
    date = (date || '')
    .replace(/[^0-9/]/g, '') // Remove non-numeric, non-slash characters
    .replace(/\/+/g, '/') // Remove duplicate slashes
    .replace(/^([2-9])/, '0$1/') // Infer if month is single digit
    .replace(/^(\d)\//, '0$1/') // Add leading zero to confirmed single digit month
    .replace(/^(\d{1,2})\/([4-9])/, '$1/0$2/') // Infer if day is single digit
    .replace(/^(\d{1,2})\/(\d)\//, '$1/0$2/') // Add leading zero to confirmed single digit day
    .replace(/^(\d{1,2})\/(\d{1,2})\/(\d{4}).+/, '$1/$2/$3'); // Remove any characters after year

    const wasBackspace = date === self.expirationDate?.slice(0, -1);
    // Nice to add slash after month, but if we don't account for backspace,
    // this makes it impossible to delete the slash.
    if (!wasBackspace) {
      date = date.replace(/^(\d{2})$/, '$1/'); // Add slash after month, if applicable
      date = date.replace(/^(\d{2}\/\d{2})$/, '$1/'); // Add slash after day, if applicable
    }

    if (strict) {
      const strictMatch = date.match(/^(\d{2})\/(\d{2})\/(\d{2,4})$/);
      if (!strictMatch) return self.expirationDate = '';
      const [, month, day, year] = strictMatch;
      if (year.length === 3) return self.expirationDate = '';
      if (year.length === 2) {
        const currentYear = new Date().getFullYear().toString().slice(0, 2);
        return self.expirationDate = `${month}/${day}/${currentYear}${year}`;
      }
    }
    
    self.expirationDate = date;
  },
  formatValues: () => {
    self.setExpirationDate(self.expirationDate, true);
  },
  setSelectedWarningLabels: (labels) => {
    self.selectedWarningLabels = labels.filter(Boolean).slice(0, 4);
  },
  toggleSelectedWarningLabel: (label) => {
    // Obviously hate that this isn't a set but c'mon, it's like 3 items long.
    if (self.selectedWarningLabels.includes(label)) {
      self.selectedWarningLabels.remove(label);
    } else {
      self.selectedWarningLabels = [
        ...self.selectedWarningLabels.slice(0, 2).filter(Boolean),
        label,
      ];
    }
  },
}))
.views(self => ({
  get formularyEntriesList() {
    return Array.from(self.formularyEntriesByNDC.values());
  },
}));


export const labelsRouteStore = LabelsRouteStore.create({});
