export const CONTAINER_SIZE_BY_SLOT_SIZE = {
  RS: 'Bottle Small',
  RM: 'Bottle Medium',
  RL: 'Bottle Large',
  CRX16: 'CRX16',
  CRX40: 'CRX40',
  RENBA: 'Renown Box',
  BSS: 'Box Small',
  BSL: 'Box Medium',
  BLS: 'Box Large',
  BLL: 'Box X-Large',
};

export const FILTERED_SLOT_SIZES = Object.keys(CONTAINER_SIZE_BY_SLOT_SIZE);
