import React from 'react';
import { upperFirst as _upperFirst } from 'lodash';

export const COLUMNS = ({ showPrescriptionFillEditModal, showSendReceiptModal, cancelPrescriptionFill, setActivePrescriptionFillFulfillment }) => [
  {
    name: 'Rx',
    fieldName: 'createdAt',
    isSortable: false,
    width: '16%',
    render: ({ content }) => (
      <div>
        <div>{content.createdAt}</div>
        <div title={content.rxId}><span className='internal-label'>RX: </span>{content.rxId}</div>
        <div><span className='internal-label'>Cost: </span>{content.hasCopay && content.copay}</div>
      </div>
      
      
    )
  },
  {
    name: 'Patient',
    fieldName: 'rxId',
    isSortable: false,
    width: '20%',
    render: ({ content }) => (
      <div>
        <div><span className='internal-label'>Patient: </span>{content.patient?.firstName} {content.patient?.lastName}</div>
        <div><span className='internal-label'>DOB: </span>{content.patient?.DOB}</div>
        <div><span className='internal-label'>Kiosk: </span>{content.routedKioskName}</div>
      </div>
    )
  },
  {
    name: 'Drug',
    fieldName: 'drug',
    isSortable: false,
    width: '30%',
    render: ({ content }) => (
      <div>
        <div title={content.drug?.name}><span className='internal-label'>Name: </span>{content.drug?.name}</div>
        <div><span className='internal-label'>NDC: </span>{content?.drug?.NDC}</div>
        <div><span className='internal-label'>Quantity: </span>{content?.quantity}</div>
      </div>
    )
  },
  {
    name: 'Status',
    fieldName: 'routedStatusName',
    isSortable: false,
    width: '20%',
    render: ({ content }) => {
      return <div>
        <div><span className='internal-label'>Filled: </span><span className={content.hasDispenseAttempt ? 'green' : 'red'}>{content.hasDispenseAttempt ? 'Yes' : 'No'}</span></div>
        <div>
          <span className='internal-label'>Payment: </span>
          <span className={ content.hasSuccessfulPayment === 'Successful' ? 'green' : 'red' }>{content.hasSuccessfulPayment}</span>
          {
            content.paymentType &&
            <span> ({ content.paymentType })</span>
          }
        </div>
        <div>
          <span className='internal-label'>Fulfilled: </span>
          <span className={content.filled ? 'green' : 'red'}>{ content.filledText }</span>
        </div>
        {
          content.hasCopay || <div className='no-copay-alert red'>No copay yet specified on Rx.</div>
        }
      </div>;
    }
  },
  {
    name: 'Actions',
    fieldName: 'Actions',
    isSortable: false,
    width: '15%',
    render: ({ content }) => (
      <div className='actions'>
        {/* {
          (!content.locked || (!content.hasDispenseAttempt && content.active)) && <button
            className='edit'
            onClick={() => showPrescriptionFillEditModal(content.id)}
          >
            Edit
          </button>
        }
        {
          content.filled && <button
            className='receipt'
            onClick={() => showSendReceiptModal(content.id)}
          >
            Send Receipt
          </button>
        } */}
        <button
          className='fulfill'
          onClick={() => setActivePrescriptionFillFulfillment(content.fulfillmentId)}
        >Open Fulfillment</button>
        {
          // !content.hasFulfillmentTypeSpecified && content.active && <button
          //   className='cancel'
          //   onClick={() => cancelPrescriptionFill(content.id)}
          // >
          //   Cancel
          // </button>
        }
      </div>
    )
  },
];
