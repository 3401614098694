import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import NotificationPaneView from './notifications-pane-view';

import usePrevious from 'hooks/usePrevious';

import { notificationsPaneRouteStore } from './domain/store';

import {
  addNotificationEffects,
  fetchNotificationsRelatedData,
  getNotificationsCountByType,
} from './helpers';

import { NOTIFICATIONS_DATA } from './constants';

function usePlaySound(type, notificationsCount) {
  const config = NOTIFICATIONS_DATA[type];

  const notificationsCountRef = useRef();
  const timeoutRef = useRef();
  const previousRef = useRef();

  notificationsCountRef.current = notificationsCount;

  useEffect(() => {
    const previous = previousRef.current;
    previousRef.current = notificationsCount;
    if (!notificationsCount) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      return;
    }

    if (!previous || !timeoutRef.current) {
      playSound();
    }
  }, [notificationsCount]);

  function playSound() {
    if (!notificationsCountRef.current) return;
    if (!config?.sound?.audio) return;
    if (config?.sound?.active?.() !== false) config.sound.audio.play();

    if (config?.sound?.interval) {
      setTimeout(() => {
        playSound();
      }, config.sound.interval);
    }
  }
}

const notifications = Object.keys(NOTIFICATIONS_DATA).sort();

const NotificationPaneContainer = () => {
  const refetchIntervalsRef = useRef([]);

  const notificationsCountByType = getNotificationsCountByType();
  const previousNotificationsCountByType = usePrevious(notificationsCountByType);

  notifications.forEach((type) => {
    usePlaySound(type, notificationsCountByType[type]);
  });

  useEffect(() => {
    fetchNotificationsRelatedData();

    Object.values(NOTIFICATIONS_DATA).forEach(({ loadData, refetchInterval }) => {
      if (loadData && refetchInterval) {
        const interval = setInterval(() => loadData(), refetchInterval);
        refetchIntervalsRef.current.push(interval);
      }
    });

    return () => {
      refetchIntervalsRef.current.forEach((interval) => clearInterval(interval));
    };
  }, []);

  useEffect(() => {
    const currentNotificationsCountByType = getNotificationsCountByType();
    addNotificationEffects(previousNotificationsCountByType, currentNotificationsCountByType);
  }, [notificationsCountByType]);


  return <NotificationPaneView
    activeTab={notificationsPaneRouteStore.activeTab}
    setActiveTab={notificationsPaneRouteStore.setActiveTab}
  />;
};

export default observer(NotificationPaneContainer);
