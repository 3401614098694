import * as ROUTE from 'constants/routes';
import * as USER_TYPE from 'models/users/constants/types';
import { FULL } from '../../constants/permissionTypes';

/**
 * In order to provide multiple roles that must be satisfied simultaneously,
 * declare them as an array inside of a permission set.
 */
export default {
  [ROUTE.DISPENSE_ATTEMPTS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
      [USER_TYPE.MEDIFRIEND_ADMIN, USER_TYPE.PHARMACIST],
    ]),
  },
  [ROUTE.DRUGS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
      [USER_TYPE.MEDIFRIEND_ADMIN, USER_TYPE.PHARMACIST],
    ]),
  },
  [ROUTE.LABELS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
      [USER_TYPE.MEDIFRIEND_ADMIN, USER_TYPE.PHARMACIST],
    ]),
  },
  [ROUTE.LOTS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
      [USER_TYPE.MEDIFRIEND_ADMIN, USER_TYPE.PHARMACIST],
    ]),
  },
  [ROUTE.STOCKING_BATCHES.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
      [USER_TYPE.MEDIFRIEND_ADMIN, USER_TYPE.PHARMACIST],
    ]),
  },
  [ROUTE.MACHINE_TECHNICIANS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
      USER_TYPE.PHARMACIST,
    ]),
  },
  [ROUTE.MEDICAL_ASSISTANTS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
      USER_TYPE.PHARMACIST,
    ]),
  },
  [ROUTE.PATIENTS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
      [USER_TYPE.MEDIFRIEND_ADMIN, USER_TYPE.PHARMACIST],
    ]),
  },
  [ROUTE.PRESCRIPTION_FILLS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
      [USER_TYPE.MEDIFRIEND_ADMIN, USER_TYPE.PHARMACIST],
    ]),
  },
  [ROUTE.PHARMACY_TECHNICIANS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
      USER_TYPE.PHARMACIST,
    ]),
  },
  [ROUTE.KEYCARDS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
      [USER_TYPE.MEDIFRIEND_ADMIN, USER_TYPE.PHARMACIST],
    ]),
  },
  [ROUTE.KIOSKS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
      [USER_TYPE.MEDIFRIEND_ADMIN, USER_TYPE.PHARMACIST],
    ]),
  },
  [ROUTE.PRESCRIPTION_FILL_UPSERT.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACY_USER,
    ]),
  },
  [ROUTE.HEALTHCARE_SITES.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
    ])
  },
  [ROUTE.VIDEO_CONFERENCES.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.PHARMACIST,
    ])
  },

  [ROUTE.ADMIN_DRUGS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
    ]),
  },
  [ROUTE.ADMIN_MACHINE_TECHNICIANS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
    ]),
  },
  [ROUTE.ADMIN_MEDICAL_ASSISTANTS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
    ]),
  },
  [ROUTE.ADMIN_MODULE_TEMPLATES.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
    ]),
  },
  [ROUTE.ADMIN_PATIENTS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
    ]),
  },
  [ROUTE.ADMIN_PHARMACY_TECHNICIANS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
    ]),
  },
  [ROUTE.ADMIN_PHYSICIANS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
    ]),
  },
  [ROUTE.ADMIN_USERS.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
    ]),
  },
  [ROUTE.ADMIN_PHARMACIES.VALUE]: {
    [FULL]: new Set([
      USER_TYPE.MEDIFRIEND_ADMIN,
    ]),
  },
};
