import { types } from 'mobx-state-tree';

import * as base from 'models/base';
import Router from './router';
import * as CONSTANTS from 'constants/index.js';

import CallRequestPharmacistActionModel from 'models/call-request-pharmacist-actions';
import CallRequestModel from 'models/call-requests';
import CamerasModel from 'models/cameras';
import HealthcareSiteModel from 'models/healthcare-sites';
import ContainerModel from 'models/containers';
import DispenseAttemptModel from 'models/dispense-attempts';
import DrugModel from 'models/drugs';
import FormularyEntryModel from 'models/formulary-entries';
import AppCommandModel from 'models/app-commands';
import LotModel from 'models/lots';
import lotDrugRecallModel from 'models/lot-drug-recalls';
import MachineTechniciansModel from 'models/machine-technicians';
import MedicalAssistantModel from 'models/medical-assistants';
import ModuleTemplatesModel from 'models/module-templates';
import PatientInsuranceModel from 'models/patient-insurances';
import PatientModel from 'models/patients';
import PatientSignaturesModel from 'models/patient-signatures';
import PharmaciesModel from 'models/pharmacies';
import PharmacistsModel from 'models/pharmacists';
import PharmacyTechniciansModel from 'models/pharmacy-technicians';
import PhysiciansModel from 'models/physicians';
import PrescriptionsModel from 'models/prescriptions';
import PrescriptionFillModel from 'models/prescription-fills';
import PrescriptionFillPharmacistConsultationRequestModel from 'models/prescription-fill-pharmacist-consultation-requests';
import PrescriptionFillKioskRoutingsModel from 'models/prescription-fill-kiosk-routings';
import PrescriptionFillUserActionsModel from 'models/prescription-fill-user-actions';
import PrescriptionFillsFulfillmentModel from 'models/prescription-fills-fulfillments';
import KioskDrugThresholdModel from 'models/kiosk-drug-thresholds';
import KioskModel from 'models/kiosks';
import KioskStatusesModel from 'models/kiosk-statuses';
import UserClientPingModel from 'models/user-client-pings';
import UserContactEventModel from 'models/user-contact-events';
import UserModel from 'models/users';
import VideoConferenceModel from 'models/video-conferences';
import VideoConferenceInvitationModel from 'models/video-conference-invitations';
import VideoConferenceParticipantConnectionModel from 'models/video-conference-participant-connections';
import KeycardsModel from 'models/keycards';
import LocationsModel from 'models/locations';
import PrescriptionFillMailOrdersModel from 'models/prescription-fill-mail-orders';
import PrescriptionFillTransfersModel from 'models/prescription-fill-transfers';
import StockingBatchesModel from 'models/stocking-batches';

// Route Stores
import * as PrescriptionInfoRouteStore from 'app/root/routes/app-frame/routes/user/dispense-attempts/routes/prescription-info/domain/store';
import * as DispenseAttemptsRoute from 'app/root/routes/app-frame/routes/user/dispense-attempts/domain/store';
import * as DrugsRoute from 'app/root/routes/app-frame/routes/admin/drugs/domain/store';
import * as LoginRoute from 'app/root/routes/login/domain/store';
import * as MedicalAssistantRouteStore from 'app/root/routes/app-frame/routes/user/medical-assistants/domain/store';
import * as ModuleTemplatesRoute from 'app/root/routes/app-frame/routes/admin/module-templates/domain/store';
import * as CallRequestsRouteStore from 'app/root/routes/app-frame/routes/user/patients/routes/call-requests/domain/store';
import * as PatientsRouteStore from 'app/root/routes/app-frame/routes/admin/patients/domain/store';
import * as PrescriptionFillsListRouteStore from 'app/root/routes/app-frame/routes/user/prescription-fills/routes/list/domain/store';
import * as PrescriptionFillsMailOrderRouteStore from 'app/root/routes/app-frame/routes/user/prescription-fills/routes/mailOrder/domain/store';
import * as PrescriptionFillsTransfersRouteStore from 'app/root/routes/app-frame/routes/user/prescription-fills/routes/transfers/domain/store';
import * as KiosksRoute from 'app/root/routes/app-frame/routes/user/kiosks/domain/store';
import * as SignUpRoute from 'app/root/routes/signup/domain/store';
import * as PharmacyTechniciansRouteStore from 'app/root/routes/app-frame/routes/user/pharmacy-technicians/domain/store';
import * as MachineTechniciansRouteStore from 'app/root/routes/app-frame/routes/user/machine-technicians/domain/store';
import * as PharmacyTechniciansAdminRouteStore from 'app/root/routes/app-frame/routes/admin/pharmacy-technicians/domain/store';
import * as VideoCallLogRoute from 'app/root/routes/app-frame/routes/user/video-conferences/domain/store';

const API_BASE = CONSTANTS.API_BASE;

base.configureGlobal({
  root: API_BASE
});


const setValue = self => ({
  setValue: (prop, value) => self[prop] = value
});


const RootStore = types.model('root', {
  route: types.model({
    dispenseAttempts: types.model({
      domain: DispenseAttemptsRoute.DispenseAttemptsRouteStore,
      routes: types.model({
        prescriptionInfo: types.model({
          domain: PrescriptionInfoRouteStore.PrescriptionInfoRouteStore
        })
      })
    }),
    moduleTemplates: types.model({
      domain: ModuleTemplatesRoute.ModuleTemplatesRouteStore,
    }),
    prescriptionFillsList: types.model({
      domain: PrescriptionFillsListRouteStore.PrescriptionFillsListRouteStore,
    }),
    prescriptionFillsMailOrder: types.model({
      domain: PrescriptionFillsMailOrderRouteStore.PrescriptionFillsMailOrderRouteStore,
    }),
    prescriptionFillsTransfers: types.model({
      domain: PrescriptionFillsTransfersRouteStore.PrescriptionFillsTransfersRouteStore,
    }),
    kiosks: types.model({
      domain: KiosksRoute.KiosksRouteStore,
    }),
    login: types.model({
      domain: LoginRoute.LoginRouteStore
    }),
    signup: types.model({
      domain: SignUpRoute.SignUpRouteStore
    }),
    callRequests: types.model({
      domain: CallRequestsRouteStore.CallRequestsRouteStore
    }),
    patients: types.model({
      domain: PatientsRouteStore.PatientsRouteStore
    }),
    pharmacyTechnicians: types.model({
      domain: PharmacyTechniciansRouteStore.PharmacyTechniciansRouteStore
    }),
    machineTechnicians: types.model({
      domain: MachineTechniciansRouteStore.MachineTechniciansRouteStore
    }),
    pharmacyTechniciansAdmin: types.model({
      domain: PharmacyTechniciansAdminRouteStore.PharmacyTechniciansRouteStore
    }),
    medicalAssistants: types.model({
      domain: MedicalAssistantRouteStore.MedicalAssistantsRouteStore
    }),
    drugs: types.model({
      domain: DrugsRoute.DrugsRouteStore
    }),
    videoCallLog: types.model({
      domain: VideoCallLogRoute.VideoCallLogRouteStore
    }),
  }),
  global: types.model({
    auth: types.model({
      activeToken: types.maybeNull(types.string),
      hasCheckedLocalStorage: types.optional(types.boolean, false)
    })
    .actions(setValue),
    callRequestPharmacistActions: CallRequestPharmacistActionModel.MSTStore,
    callRequests: CallRequestModel.MSTStore,
    cameras: CamerasModel.MSTStore,
    healthcareSites: HealthcareSiteModel.MSTStore,
    containers: ContainerModel.MSTStore,
    dispenseAttempts: DispenseAttemptModel.MSTStore,
    formularyEntries: FormularyEntryModel.MSTStore,
    videoConferences: VideoConferenceModel.MSTStore,
    videoConferenceInvitations: VideoConferenceInvitationModel.MSTStore,
    videoConferenceParticipantConnections: VideoConferenceParticipantConnectionModel.MSTStore,
    appCommands: AppCommandModel.MSTStore,
    moduleTemplates: ModuleTemplatesModel.MSTStore,
    patientInsurances: PatientInsuranceModel.MSTStore,
    patients: PatientModel.MSTStore,
    patientSignatures: PatientSignaturesModel.MSTStore,
    pharmacies: PharmaciesModel.MSTStore,
    pharmacists: PharmacistsModel.MSTStore,
    physicians: PhysiciansModel.MSTStore,
    prescriptions: PrescriptionsModel.MSTStore,
    prescriptionFillUserActions: PrescriptionFillUserActionsModel.MSTStore,
    prescriptionFills: PrescriptionFillModel.MSTStore,
    prescriptionFillPharmacistConsultationRequests: PrescriptionFillPharmacistConsultationRequestModel.MSTStore,
    prescriptionFillKioskRoutings: PrescriptionFillKioskRoutingsModel.MSTStore,
    prescriptionFillsFulfillment: PrescriptionFillsFulfillmentModel.MSTStore,
    kioskDrugThresholds: KioskDrugThresholdModel.MSTStore,
    kiosks: KioskModel.MSTStore,
    userClientPings: UserClientPingModel.MSTStore,
    userContactEvents: UserContactEventModel.MSTStore,
    users: UserModel.MSTStore,
    kioskStatuses: KioskStatusesModel.MSTStore,
    drugs: DrugModel.MSTStore,
    machineTechnicians: MachineTechniciansModel.MSTStore,
    pharmacyTechnicians: PharmacyTechniciansModel.MSTStore,
    medicalAssistants: MedicalAssistantModel.MSTStore,
    keycards: KeycardsModel.MSTStore,
    locations: LocationsModel.MSTStore,
    prescriptionFillMailOrders: PrescriptionFillMailOrdersModel.MSTStore,
    prescriptionFillTransfers: PrescriptionFillTransfersModel.MSTStore,
    lots: LotModel.MSTStore,
    lotDrugRecalls: lotDrugRecallModel.MSTStore,
    stockingBatches: StockingBatchesModel.MSTStore,
  }),
  router: types.optional(types.frozen())
});

const rootStore = RootStore
.create({
  route: {
    dispenseAttempts: {
      domain: DispenseAttemptsRoute.dispenseAttemptsRouteStore,
      routes: {
        prescriptionInfo: {
          domain: PrescriptionInfoRouteStore.prescriptionInfoRouteStore,
        }
      }
    },
    moduleTemplates: {
      domain: ModuleTemplatesRoute.moduleTemplatesRouteStore,
    },
    prescriptionFillsList: {
      domain: PrescriptionFillsListRouteStore.prescriptionFillsListRouteStore,
    },
    prescriptionFillsMailOrder: {
      domain: PrescriptionFillsMailOrderRouteStore.prescriptionFillsMailOrderRouteStore,
    },
    prescriptionFillsTransfers: {
      domain: PrescriptionFillsTransfersRouteStore.prescriptionFillsTransfersRouteStore,
    },
    kiosks: {
      domain: KiosksRoute.kiosksRouteStore,
    },
    login: {
      domain: LoginRoute.loginRouteStore
    },
    signup: {
      domain: SignUpRoute.signUpRouteStore
    },
    callRequests: {
      domain: CallRequestsRouteStore.callRequestsRouteStore
    },
    patients: {
      domain: PatientsRouteStore.patientsRouteStore
    },
    pharmacyTechnicians: {
      domain: PharmacyTechniciansRouteStore.pharmacyTechniciansRouteStore
    },
    machineTechnicians: {
      domain: MachineTechniciansRouteStore.machineTechniciansRouteStore
    },
    pharmacyTechniciansAdmin: {
      domain: PharmacyTechniciansAdminRouteStore.pharmacyTechniciansRouteStore
    },
    medicalAssistants: {
      domain: MedicalAssistantRouteStore.medicalAssistantsRouteStore
    },
    drugs: {
      domain: DrugsRoute.drugsRouteStore
    },
    videoCallLog: {
      domain: VideoCallLogRoute.videoCallLogRouteStore
    },
  },
  global: {
    auth: {},
    callRequestPharmacistActions: CallRequestPharmacistActionModel.createStore(),
    callRequests: CallRequestModel.createStore(),
    cameras: CamerasModel.createStore(),
    healthcareSites: HealthcareSiteModel.createStore(),
    containers: ContainerModel.createStore(),
    dispenseAttempts: DispenseAttemptModel.createStore(),
    formularyEntries: FormularyEntryModel.createStore(),
    videoConferences: VideoConferenceModel.createStore(),
    videoConferenceInvitations: VideoConferenceInvitationModel.createStore(),
    videoConferenceParticipantConnections: VideoConferenceParticipantConnectionModel.createStore(),
    appCommands: AppCommandModel.createStore(),
    moduleTemplates: ModuleTemplatesModel.createStore(),
    patientInsurances: PatientInsuranceModel.createStore(),
    patients: PatientModel.createStore(),
    patientSignatures: PatientSignaturesModel.createStore(),
    pharmacies: PharmaciesModel.createStore(),
    pharmacists: PharmacistsModel.createStore(),
    physicians: PhysiciansModel.createStore(),
    prescriptions: PrescriptionsModel.createStore(),
    prescriptionFillUserActions: PrescriptionFillUserActionsModel.createStore(),
    prescriptionFills: PrescriptionFillModel.createStore(),
    prescriptionFillPharmacistConsultationRequests: PrescriptionFillPharmacistConsultationRequestModel.createStore(),
    prescriptionFillKioskRoutings: PrescriptionFillKioskRoutingsModel.createStore(),
    prescriptionFillsFulfillment: PrescriptionFillsFulfillmentModel.createStore(),
    kioskDrugThresholds: KioskDrugThresholdModel.createStore(),
    kiosks: KioskModel.createStore(),
    userClientPings: UserClientPingModel.createStore(),
    userContactEvents: UserContactEventModel.createStore(),
    users: UserModel.createStore(),
    kioskStatuses: KioskStatusesModel.createStore(),
    drugs: DrugModel.createStore(),
    machineTechnicians: MachineTechniciansModel.createStore(),
    pharmacyTechnicians: PharmacyTechniciansModel.createStore(),
    medicalAssistants: MedicalAssistantModel.createStore(),
    keycards: KeycardsModel.createStore(),
    locations: LocationsModel.createStore(),
    prescriptionFillMailOrders: PrescriptionFillMailOrdersModel.createStore(),
    prescriptionFillTransfers: PrescriptionFillTransfersModel.createStore(),
    lots: LotModel.createStore(),
    lotDrugRecalls: lotDrugRecallModel.createStore(),
    stockingBatches: StockingBatchesModel.createStore(),
  },
  router: Router,
});

export default rootStore;
