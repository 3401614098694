import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import InfoView from './info-view';

import { lotInfoRouteStore } from './domain/store';

import fetchLotById from './helpers/fetchLotById';
import listDrugsByLot from './helpers/listDrugsByLot';

// Hooks
import useActivePharmacistPrompt from 'singletons/activePharmacistPrompt/useActivePharmacistPrompt';

const InfoContainer = ({ activeLotId }) => {
  useEffect(() => {
    fetchLotById(activeLotId);
    listDrugsByLot(activeLotId);

    lotInfoRouteStore.clearDrugIdsToBeRecalled();

    return () => {
      lotInfoRouteStore.setInitialState();
    };
  }, [activeLotId]);

  const { initiatePrompt } = useActivePharmacistPrompt();

  function executeDrugRecalls() {
    initiatePrompt({
      timeout: 10_000,
      onIdentify: () => {},
      onPharmacist: ({ pharmacist }) => {
        lotInfoRouteStore.executeDrugRecalls({ actingPharmacistId: pharmacist.id });
      },
    });
  }

  return <InfoView
    lotInfoRouteStore={lotInfoRouteStore}
    executeDrugRecalls={executeDrugRecalls}
  />;
};

export default observer(InfoContainer);
