import Http from 'services/http/index.js';
import { API_BASE } from '../../constants';

import stores from 'bootstrap/store';

const http = new Http();

// // sets token to requests if token exists and matches api base
http.addPreRequest((config = {}) => {
  const token = stores.global.auth.activeToken;
  if (config.url.match(API_BASE) && !!token) {
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${token}`;
    config.mode = 'cors';
  }
  return config;
});

http.addPostResponseError((err) => {
  if (err.message.match('Unauthorized') || err.message.match('The access token provided is invalid')) {
    localStorage.removeItem('activeToken');
    localStorage.removeItem('userDataById');
    window.location.reload();
  }
  return Promise.reject(err);
});

export default http;
